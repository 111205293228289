import { createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
  pagination: {
    page: 1,
    page_size: 10,
    search: "",
  },
};

const paginationSlice = createSlice({
  name: "paginationSlice",
  initialState,
  reducers: {
    setPage: (state, { payload }) => {
      state.pagination.page = payload;
    },
    setPerPage: (state, { payload }) => {
      state.pagination.page_size = payload.value;
    },
    setSearch: (state, { payload }) => {
      state.pagination.search = payload.value;
    },
    setResetPagination: (state) => {
      state.pagination = initialState.pagination;
    },
  },
});

export const { setPage, setPerPage, setSearch, setResetPagination } =
  paginationSlice.actions;

export default paginationSlice.reducer;

// //Selector
export const usePaginationSelector = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setResetPagination());
  }, []);

  return useSelector((state) => {
    return state.paginationReducer;
  });
};
