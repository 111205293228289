import React from "react";
import {
  FormErrorMessage,
  FormControl,
  FormLabel,
  Box,
  Flex,
  Heading,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import { useAuth } from "contexts/AuthContext";
import { useAuthSelector } from "store/slices/auth";
import { sendInvite } from "store/slices/auth";
import { useDispatch } from "react-redux";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Card from "components/card/Card";
import { PrimayButton } from "components/customs/buttons";
import { useProtectedAdminRoutes } from "lib/hooks";
import { CustomFormInput } from "components/customs/Input";

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email.")
    .required("Email is required."),
});

function InviteAdmin() {
  //redirect the non-superadmin users
  useProtectedAdminRoutes();
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "#D0BEA3";
  const inputColor = "#75582B";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const bgButtonGradient = "linear-gradient(180deg, #FFCE88 0%, #DD9122 100%)";
  const { login } = useAuth();
  const [show, setShow] = React.useState(false);

  const dispatch = useDispatch();
  const handleClick = () => setShow(!show);
  const auth = useAuthSelector();
  const history = useHistory();
  const methods = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields },
  } = methods;

  const onSubmit = async (data) => {
    const res = await dispatch(
      sendInvite({ email: data?.email, password: data?.password })
    );
    if (res.payload === API_SUCCESS_RESPONSE_TAG) {
      reset({ email: "" });
      // history.push("/admin/admins");
    }
  };

  return (
    // <DefaultAuth illustrationBackground={illustration} image={illustration}>
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        mb={{ base: "0px", lg: "20px" }}
        sx={{
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Heading
            textAlign="center"
            color={textColor}
            fontSize="36px"
            mb="10px"
          >
            Invite Admin
          </Heading>
          <Text
            // textAlign="center"
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter the email to send invitation!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Flex align="center" mb="25px">
            <HSeparator />
          </Flex>
          <FormProvider {...methods}>
            <fieldset disabled={isSubmitting}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl isInvalid={errors.email}>
                  <Box mb="24px">
                    <CustomFormInput
                      {...{
                        placeholder: "example@email.com",
                        type: "text",
                        name: "email",
                        label: "Email",
                        isRequired: true,
                      }}
                    />
                  </Box>
                  {/* <Flex justifyContent="center"> */}
                  <PrimayButton
                    label="Send Invite"
                    isLoading={isSubmitting}
                    w="100%"
                  />
                  {/* </Flex> */}
                  {/* <Button
                isLoading={isSubmitting}
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                bg={bgButtonGradient}
                _hover={{ opacity: 0.8 }}
              >
                <Text
                  fontFamily={"'DM Sans', sans-serif"}
                  fontWeight="700"
                  fontSize="32px"
                  lineHeight="28px"
                  textAlign="center"
                >
                  Invite
                </Text>
              </Button> */}
                </FormControl>
              </form>
            </fieldset>
          </FormProvider>
        </Flex>
      </Card>
    </Box>

    // </DefaultAuth>
  );
}

export default InviteAdmin;
