import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { Avatar, Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import avatar from "assets/img/avatars/avatar4.png";
import Card from "components/card/Card";
import { HSeparator } from "components/separator/Separator";
import { useAuthSelector } from "store/slices/auth";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomFormInput } from "components/customs/Input";
import { PrimayButton, UploadButton } from "components/customs/buttons";
import { useColors } from "components/customs/colors";
import { schema } from "./schema";
import { updateProfileThunk } from "store/slices/auth";
import { BACKEND_BASE_URL } from "lib/constants";

export function useSearchParams() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  return { searchParams };
}

export function Reward() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const auth = useAuthSelector();
  const { searchParams } = useSearchParams();

  const id = searchParams.get("id");
  const action = history?.location?.pathname?.split("/")?.at(-1);
  const isEditMode = action === "edit";
  const users = auth?.users;
  const current_user_id = id;
  const user = users?.data?.results?.find(
    (user) => user.id === current_user_id
  );

  const { borderColor } = useColors();

  const defaultValues = {
    name: user?.name ?? "",
    email: user?.email ?? "",
    username: user?.username ?? "",
    rank: user?.rank ?? 0,
    highest_score: user?.highest_score ?? 0,
    user_id: id ?? "",
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      name: user?.name ?? "",
      email: user?.email ?? "",
      username: user?.username ?? "",
      rank: user?.rank ?? 0,
      highest_score: user?.highest_score ?? 0,
      user_id: id ?? "",
    });
  }, [auth]);

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty, dirtyFields },
  } = methods;

  const onSubmit = async (data) => {
    if (!isDirty) return;
    const payload = Object.keys(data).reduce((acc, current) => {
      if (dirtyFields[current]) {
        acc[current] = data[current];
      }
      return acc;
    }, {});

    const res = await dispatch(
      updateProfileThunk({ ...payload, user_id: current_user_id })
    );
    if (res.payload === API_SUCCESS_RESPONSE_TAG) {
      // reset();
    }
  };

  function toggleEditMode() {
    history.push(`${location.pathname}/edit?id=${id}`);
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card mb={{ base: "0px", lg: "20px" }} align="center">
        <Avatar
          mx="auto"
          src={`${BACKEND_BASE_URL}/${user?.profile_picture}` || avatar}
          h="145px"
          w="145px"
          border="4px solid"
          borderColor={borderColor}
        />
        {isEditMode && (
          <UploadButton label="Upload" user_id={current_user_id} />
        )}
        <Flex align="center" my="25px">
          <HSeparator />
        </Flex>

        <FormProvider {...methods}>
          <fieldset disabled={!(isEditMode || isDirty)}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  lg: "repeat(2, 1fr)",
                }}
                gap={{ base: 3, lg: 10 }}
              >
                <GridItem>
                  <CustomFormInput
                    {...{
                      placeholder: "Your name",
                      type: "text",
                      name: "name",
                      label: "Name",
                      isRequired: true,
                    }}
                  />

                  <CustomFormInput
                    {...{
                      placeholder: "Your email",
                      type: "email",
                      name: "email",
                      label: "Email",
                      isRequired: true,
                    }}
                  />
                  <CustomFormInput
                    {...{
                      placeholder: "Your username",
                      type: "text",
                      name: "username",
                      label: "Username",
                      isRequired: true,
                    }}
                  />
                </GridItem>
                <GridItem>
                  <CustomFormInput
                    {...{
                      placeholder: "Reward ID",
                      type: "text",
                      name: "user_id",
                      label: "User ID",
                      isRequired: true,
                    }}
                  />

                  <CustomFormInput
                    {...{
                      placeholder: "Reward rank",
                      type: "text",
                      name: "rank",
                      label: "Rank",
                      isRequired: true,
                    }}
                  />
                  <CustomFormInput
                    {...{
                      placeholder: "Reward's highest score",
                      type: "text",
                      name: "highest_score",
                      label: "Highest Score",
                      isRequired: true,
                    }}
                  />
                </GridItem>
              </Grid>
              {isEditMode && (
                <PrimayButton
                  key="submit"
                  label="Update"
                  isLoading={isSubmitting}
                  disabled={!isDirty}
                />
              )}
            </form>
          </fieldset>
          {!isEditMode && (
            <Flex justifyContent="center">
              <PrimayButton
                key="button"
                type="button"
                label="Edit"
                onClick={toggleEditMode}
                disabled={false}
                isLoading={isSubmitting}
              />
            </Flex>
          )}
        </FormProvider>
      </Card>
    </Box>
  );
}
