import { Flex, Text } from "@chakra-ui/react";
import { useColors } from "./colors";

export function CustomNoDataFound({ value }) {
  const { textColor } = useColors();
  return (
    <Flex height="100%" alignItems="center" justifyContent="center">
      <Text
        fontSize={{ sm: "xl", md: "2xl" }}
        textAlign="center"
        color={textColor}
      >
        {value ?? "No Data Found"}
      </Text>
    </Flex>
  );
}
