import axios from "axios";
import { ENDPOINTS } from "lib/constants";
import { LOCAL_STROAGE_USER_GET_KEY } from "lib/constants";
import { API_BASE_URL } from "lib/constants";
import { LOCAL_STROAGE_KEYS } from "lib/constants";
import { setTokensInLocalStorage } from "lib/helpers";
import { getAuthTokens } from "lib/helpers";
import { toast } from "react-toastify";

export const axiosPublic = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export const axiosPrivate = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

const handleSessionExpired = () => {
  localStorage.removeItem(LOCAL_STROAGE_USER_GET_KEY);
  toast.error("Your session has expired. Please login again.");
  window.location.href = "/auth/sign-in";
};

axiosPrivate.interceptors.request.use(
  (config) => {
    const userFromLocalStroage = localStorage.getItem(
      LOCAL_STROAGE_USER_GET_KEY
    );
    if (!userFromLocalStroage) return config;

    const rawUser = JSON.parse(userFromLocalStroage).user;
    const user = JSON.parse(rawUser);
    const accessToken = user?.data?.tokens?.access?.token || "TOKEN NOT FOUND";
    if (!config?.headers["Authorization"]) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosPrivate.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;
    if (error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      const refreshToken = getAuthTokens()?.refreshToken;
      // if (!refreshToken) {
      //   handleSessionExpired();
      // }

      try {
        const response = await axios.post(
          `${API_BASE_URL}${ENDPOINTS.REFRESH_TOKEN}`,
          { refreshToken },
          {
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
          }
        );

        if (response.code === 401) {
          handleSessionExpired();
        }

        const newAccessToken = response.data.access.token;
        const newRefreshToken = response.data.refresh.token;
        setTokensInLocalStorage({
          accessToken: newAccessToken,
          refreshToken: newRefreshToken,
        });

        originalConfig.headers.Authorization = `Bearer ${newAccessToken}`;
        return axios(originalConfig);
      } catch (refreshError) {
        // handleSessionExpired();
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosPrivate;
