import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { AuthProvider } from "contexts/AuthContext";
import ProtectedRoute from "contexts/ProtectedRoute";
import { store } from "store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { persistor } from "store";
import { SocketProvider } from "contexts/SocketContext";
import { CheckSigninProvider } from "contexts/CheckSignin";

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ToastContainer />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider>
              <Router>
            <SocketProvider>
                <CheckSigninProvider>
                  <Switch>
                    <Route path={`/auth`} component={AuthLayout} />
                    <ProtectedRoute path={`/admin`} component={AdminLayout} />
                    <Redirect from="/" to="/auth/sign-in" />
                  </Switch>
                </CheckSigninProvider>
            </SocketProvider>
              </Router>
          </AuthProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
