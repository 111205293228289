// Chakra imports
import {
  background,
  Box,
  Flex,
  HStack,
  SimpleGrid,
  Spinner,
  Tag,
  TagLabel,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { rewardsTableColumns } from "./rewardsColumns";
import { DATA_FETCHING_STATUSES } from "lib/constants";
import { RewardsTable } from "./rewardTable";
import { useColors } from "components/customs/colors";
import { useHistory, useLocation } from "react-router-dom";
import { deleteUserThunk } from "store/slices/auth";
import { useLoadingSelector } from "store/slices/loading";
import { formatDate } from "lib/helpers";
import { fetchRewardsThunk, useRewardsSelector } from "store/slices/rewards";
import { formatRewardType } from "lib/helpers";
import { CustomClaimTag } from "components/customs/tags";
import { deleteRewardsThunk } from "store/slices/rewards";
import { isAfter, isBefore } from "date-fns";
import { ConfirmationModal } from "components/customs/confirmationModal";


export function Rewards() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenConfirmModal,
    onOpen: onOpenConfirmModal,
    onClose: onCloseConfirmModal,
  } = useDisclosure();
  const {
    isOpen: isOpenBulkCreateModal,
    onOpen: onOpenBulkCreateModal,
    onClose: onCloseBulkCreateModal,
  } = useDisclosure();
  const { textColor } = useColors();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const rewardsState = useRewardsSelector();
  const rewards = rewardsState?.rewards;
  const totalPages = rewards?.data?.totalPages;

  const loading = useLoadingSelector();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState("");
  const [selectedDate, setSeletedDate] = useState(new Date());

  useEffect(() => {
    dispatch(
      fetchRewardsThunk({ page: currentPage, selectedDate, sortBy: "rank" })
    );
  }, [currentPage, selectedDate]);

  function handleSelectDate(selectedDate) {
    setSeletedDate(selectedDate);
  }

  function handleEdit({ id }) {
    onOpen();
    history.push(`${location.pathname}/edit?id=${id}`);
  }

  function handleDelete({ id }) {
    dispatch(deleteRewardsThunk({ id, selectedDate }));
  }

  function handleAction({ action, id }) {
    if (loading) return;

    if (action === "Edit") {
      handleEdit({ id });
      return;
    }

    if (action === "Delete") {
      onOpenConfirmModal();
      setSelectedRow(id);
      return;
    }
  }

  const tableRows = useMemo(
    () =>
      rewards?.data?.results?.map((reward, index) => ({
        id: reward.id,
        winner: reward?.winner_id?.id ?? "N/A",
        winner_name: reward?.winner_id?.username ?? "N/A",  
        rank_index: reward.rank ?? index + 1,
        title: reward?.rewardType
          ? formatRewardType(reward?.rewardType).split(" ").slice(1).join(" ")
          : "",
        reward_value: reward.rewardValue ?? "N/A",
        claim_status: reward.claim_status ? (
          <CustomClaimTag label="claimed" />
        ) : (
          <CustomClaimTag label="pending" />
        ),
        reward_awarded_date: reward?.rewardDate
          ? formatDate(new Date(reward?.rewardDate))
          : "N/A",
        actions:
          isBefore(new Date(reward?.rewardDate), new Date()) &&
          !!reward?.winner_id ? (
            <CustomClaimTag label="Already Awarded" />
          ) : (
            <HStack spacing={4}>
              {["Edit", "Delete"].map((value) => (
                <Tag
                  disabled={
                    loading ||
                    isBefore(new Date(reward?.rewardDate), new Date())
                  }
                  pointerEvents={
                    loading ||
                    isBefore(new Date(reward?.rewardDate), new Date())
                      ? "none"
                      : "auto"
                  }
                  _disabled={{ opacity: 0.8, cursor: "not-allowed" }}
                  onClick={(e) => {
                    // if (!reward?.winner_id) return;
                    handleAction({ event: e, action: value, id: reward.id });
                  }}
                  size="lg"
                  key={value}
                  variant="solid"
                  sx={{ backgroundColor: "secondaryGray.900" }}
                  _hover={{ cursor: "pointer" }}
                >
                  <TagLabel>{value}</TagLabel>
                </Tag>
              ))}
            </HStack>
          ),
      })),
    [rewardsState, loading, selectedDate]
  );

  let component = (
    <Flex justifyContent="center" mt="28">
      <Spinner size="xl" color="brand.950" />
    </Flex>
  );

  if (rewards?.status === DATA_FETCHING_STATUSES.DATA_LOADED) {
    component = (
      <RewardsTable
        columnsData={rewardsTableColumns}
        tableData={tableRows}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        selectedDate={selectedDate}
        handleSelectDate={handleSelectDate}
        {...{
          isOpen,
          onOpen,
          onClose,
          isOpenBulkCreateModal,
          onOpenBulkCreateModal,
          onCloseBulkCreateModal,
        }}
      />
    );
  }
  return (
    <>
      {isOpenConfirmModal && (
        <ConfirmationModal
          {...{
            headerText: "Delete Reward Confirmation",
            isOpen: isOpenConfirmModal,
            onOpen: onOpenConfirmModal,
            onClose: () => {
              setSelectedRow("");
              onCloseConfirmModal();
            },
            handleAction: () => handleDelete({ id: selectedRow }),
          }}
        />
      )}
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid mb="20px" spacing={{ base: "20px", xl: "20px" }}>
          {component}
        </SimpleGrid>
      </Box>
    </>
  );
}
