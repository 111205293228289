import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Grid,
  GridItem,
  Box,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { PrimayButton } from 'components/customs/buttons';
import { FormProvider, useForm } from 'react-hook-form';
import Card from 'components/card/Card';
import { CustomInput } from 'components/customs/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSocketContext } from 'contexts/SocketContext';
import { SOCKET_EVENTS } from 'lib/constants';
import { useAuthSelector } from "store/slices/auth";

export function BroadCastModal({ isOpen, onClose, updateMessages, setIsBroadcast }) {
  const { socket } = useSocketContext();
  const auth = useAuthSelector();
  const user = auth?.user?.data?.user;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const schema = yup.object().shape({
    broadcastMessage: yup
      .string()
      .required('Broadcast message cannot be empty')
      .min(10, 'Message must be at least 10 characters'),
  });

  const defaultValues = {
    broadcastMessage: '',
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;
  
  const onSubmit = async (message) => {
    if (message && message.broadcastMessage) {
      const broadcastData = {
        senderId: user?.id,
        senderRole: user?.role,
        receiverId: null,
        receiverRole: "user",
        message: message.broadcastMessage,
        isRead: true,
        id: new Date().getTime().toString(),
        senderProfile: user?.profile_picture, 
        senderName: user?.username, 
      };
  
      socket?.emit(SOCKET_EVENTS.SEND_MESSAGE_TO_ALL, broadcastData);
      updateMessages({ message: broadcastData });
    }
    onClose();
    setValue("broadcastMessage", "");
    setIsBroadcast(false);
  };

  const handleClose = () => {
    setValue('broadcastMessage', '');
    setIsBroadcast(false);
    onClose();
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl' isCentered>
      <ModalOverlay 
      />
      <ModalContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>
              <Text
                color={textColor}
                fontSize='30px'
                fontWeight='700'
                lineHeight='100%'
              >
                Broadcast Message
              </Text>
            </ModalHeader>

            <ModalBody>
              <Box>
                <Card mb={{ base: '0px', lg: '20px' }} align='center'>
                  <Grid templateColumns='repeat(1, 1fr)' gap={4}>
                    <GridItem>
                      <CustomInput
                        placeholder='Type the message you want to send.'
                        type='textarea'
                        name='broadcastMessage'
                        label='Broadcast Message'
                        isRequired={true}
                        error={errors.broadcastMessage?.message}
                        styles={{
                          width: '94%',
                          height: '250px',
                          resize: 'none',
                          overflowY: 'auto',
                          borderRadius: '8px',
                          padding: '12px',
                          fontSize: '18px',
                          fontWeight: '500',
                          color: '#fff !important',
                          background: '#E2BA80',
                        }}
                      />
                    </GridItem>
                    <GridItem
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <PrimayButton
                        key='submit'
                        label='Send'
                        type='submit'
                        isLoading={isSubmitting}
                        style={{ width: '48%', color: '#000', fontSize: '18px' }}
                      />
                      <PrimayButton
                        key='cancel'
                        label='Cancel'
                        type='button'
                        onClick={() => {
                            handleClose();
                        }}
                        style={{ width: '48%' , color: '#000', fontSize: '18px'}}
                      />
                    </GridItem>
                  </Grid>
                </Card>
              </Box>
            </ModalBody>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
