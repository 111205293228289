import { REWARD_TYPES_MAP } from "lib/constants";
import { REWARD_TYPES } from "lib/constants";
import * as yup from "yup";
import { parse, isAfter, isEqual, startOfToday } from "date-fns";

const REWARDS_VALIDATION_VALUES = () =>
  Array.from({ length: 20 }, (_, i) => i + 1);

export const schema = yup.object().shape({
  rewardValue: yup
    .number()
    .integer("Decimal numers are not allowed.")
    .transform((value, originalValue) => {
      return typeof originalValue === "string" && originalValue?.trim() === ""
        ? NaN
        : value;
    })
    .when("rewardType", {
      is: REWARD_TYPES_MAP.GIFT_CARD,
      then: (schema) =>
        schema
          .typeError("Card amount must be a number")
          .required("Card amount is required.")
          .min(1, "Card amount cannot be zero or negative number."),
      otherwise: (schema) =>
        schema
          .typeError("Acorns quantity must be a number")
          .required("Acorns are required.")
          .min(1, "Acorns quantity cannot be zero or negative number."),
    }),
  rewardType: yup
    .string()
    .trim()
    .oneOf(REWARD_TYPES, "Invalid Reward type.")
    .required("Reward type is required."),
  cardCode: yup.string().when("rewardType", {
    is: (value) => value === REWARD_TYPES_MAP.GIFT_CARD,
    then: () =>
      yup
        .string()
        .trim()
        .min(2, "Card code cannot be empty.")
        .required("Card code is required."),
    // otherwise: yup.string().notRequired(), // Skip validation otherwise
  }),
  rank: yup
    .number()
    .typeError("Only numbers are allowed.")
    .oneOf(
      REWARDS_VALIDATION_VALUES(),
      "Only one of these (1,2,3) values are required"
    )
    .required("Rank is required."),
  rewardDate: yup
    .string()
    .required("Reward Date is required.")
    .test(
      "is-future-date",
      "Reward Date cannot be in the past.",
      function (value) {
        if (!value) return false; // Handle required case
        const parsedDate = parse(value, "yyyy-MM-dd", new Date());
        const today = startOfToday();
        return isAfter(parsedDate, today) || isEqual(parsedDate, today); // allow today or future date
      }
    ),
});
