export const achievementsColumns = [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Amount",
    accessor: "reward_value",
  },
  {
    Header: "Claim Status",
    accessor: "claim_status",
  },
  {
    Header: "Reward Awarded Date",
    accessor: "reward_awarded_date",
  },
];
