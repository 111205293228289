// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DATA_FETCHING_STATUSES } from "lib/constants";
import axiosPrivate from "lib/config/axios";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import { ENDPOINTS } from "lib/constants";
import { axiosPublic } from "lib/config/axios";
import { nullableObject } from "lib/helpers";
import { setAllUsers } from "./chatUsers"

export const signUpThunk = createAsyncThunk(
  "appAuth/signUp",
  async (payload, { dispatch }) => {
    try {
      const response = await axiosPrivate.post(ENDPOINTS.SIGN_UP, payload);
      const data = response.data;
      dispatch(
        setUser({
          data: { tokens: data.tokens, user: data.user },
          status: DATA_FETCHING_STATUSES.DATA_LOADED,
        })
      );
      toast.success("Signup successful");
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }
);

export const loginThunk = createAsyncThunk(
  "appAuth/login",
  async (payload, { dispatch }) => {
    try {
      const response = await axiosPublic.post(ENDPOINTS.LOGIN, payload);
      const data = response.data;
      dispatch(
        setUser({
          data: { tokens: data.tokens, user: data.user },
          status: DATA_FETCHING_STATUSES.DATA_LOADED,
        })
      );
      toast.success("Login successful");
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.message === "Network Error") {
        toast.error("Network error. Please check your internet connection.");
        localStorage.clear();
        return;
      }
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
        return;
      }
      toast.error("Something went wrong");
    }
  }
);

export const logoutThunk = createAsyncThunk(
  "appAuth/logout",
  async (payload) => {
    try {
      await axiosPrivate.post(ENDPOINTS.LOGOUT, payload);
      toast.success("Logout successful");
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }
);

export const forgotPasswordThunk = createAsyncThunk(
  "appAuth/forgotPassword",
  async (payload) => {
    try {
      await axiosPrivate.post(ENDPOINTS.FORGOT_PASSWORD, payload);
      toast.success("A reset password link has been sent to your email.");
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }
);

export const resetPasswordThunk = createAsyncThunk(
  "appAuth/resetPassword",
  async (payload) => {
    try {
      await axiosPrivate.post(ENDPOINTS.RESET_PASSWORD, payload);
      toast.success(
        "Your password has been reset. Please login with updated password."
      );
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }
);

export const updateProfilePictureThunk = createAsyncThunk(
  "appAuth/updateProfilePicture",
  async (payload, { dispatch, getState }) => {
    const state = getState().authReducer.user.data;
    const { user_id } = payload;

    let formData = new FormData();
    formData.append("profile_picture", payload.file, payload.file?.name);

    try {
      dispatch(setIsFileUploading(true));
      const response = await axiosPrivate.post(
        `${ENDPOINTS.UPDATE_PROFILE_PICTURE}/${user_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data; boundary=myboundary",
          },
          onUploadProgress: () => {},
        }
      );
      dispatch(fetchSingleUserThunk({ id: user_id }));
      const data = response.data;
      const current_user_id = state.user.id;
      if (user_id === current_user_id) {
        dispatch(
          setUser({
            data: { ...state, user: data.user },
            status: DATA_FETCHING_STATUSES.DATA_LOADED,
          })
        );
      } else {
        dispatch(fetchSingleUserThunk({ id: user_id }));
      }
      dispatch(setIsFileUploading(false));
      // toast.success("Profile picture updated successfully");
      return { responseStatus: API_SUCCESS_RESPONSE_TAG, data };
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }
);

export const updateProfileThunk = createAsyncThunk(
  "appAuth/updateProfile",
  async (payload, { dispatch, getState }) => {
    const state = getState().authReducer.user.data;
    const { user_id, ...restPayload } = payload;
    try {
      const response = await axiosPrivate.patch(
        `${ENDPOINTS.USERS}/${user_id}`,
        restPayload
      );
      const data = response.data;
      const current_user_id = state.user.id;
      if (user_id === current_user_id) {
        dispatch(
          setUser({
            data: { ...state, user: data.user },
            status: DATA_FETCHING_STATUSES.DATA_LOADED,
          })
        );
      } else {
        dispatch(fetchSingleUserThunk({ id: user_id }));
      }

      toast.success("Profile updated successfully");
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }
);

export const deleteUserThunk = createAsyncThunk(
  "appAuth/deleteUser",
  async (payload, { dispatch }) => {
    const { id, role, search, searchType } = payload;
    try {
      await axiosPrivate.delete(`${ENDPOINTS.USERS}/${id}`);
      await dispatch(
        fetchUsersThunk({
          page: 1,
          role,
        })
      );
      toast.success("User deleted successfully");
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }
);

export const sendInvite = createAsyncThunk(
  "appAuth/sendInvite",
  async (payload) => {
    try {
      await axiosPrivate.post(ENDPOINTS.SEND_INVITATION, payload);
      toast.success("Invitation sent successfully");
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }
);

const ROLES = {
  admins: "admin",
  players: "user",
};
export const CHAT_USERS_ACTION_TYPE = "debouncedSearch"

export const fetchUsersThunk = createAsyncThunk(
  "appAuth/fetchUsers",
  async ({ type, page, role, limit, search, searchType, actionType }, { dispatch }) => {
    try {
      if(actionType === CHAT_USERS_ACTION_TYPE) {
        dispatch(setAllUsers({ usersList: [] }))
      }
         // Conditionally call the game history API
         let gameHistoryPromise;
         if (type !== "chat") {
           gameHistoryPromise = axiosPrivate.get(
             ENDPOINTS.PLAYER_GAME_HISTORY,
             {
               params: {
                 page,
               },
             }
           );
         }
      const usersPromise = axiosPrivate.get(ENDPOINTS.USERS, {
        params: {
          page,
          ...(ROLES[role] ? { role: ROLES[role] } : !!role ? { role } : null),
          keyword: search,
          ...nullableObject({ searchType }),
          ...nullableObject({ limit }),
        },
      });

      const [usersResponse, gameHistoryResponse] = await Promise.all([
        usersPromise,
        gameHistoryPromise,
      ]);

      let users = usersResponse.data;
      if (type !== "chat") {
        const gameHistory = gameHistoryResponse.data;
        users.results = users.results.map((u) => {
          const userHistory = gameHistory?.[u?.id];
          return {
            ...u,
            last_played: userHistory?.playedAtArray?.at(-1)?.playedAt,
            last_login: userHistory?.userLoginAtArray?.at(-1)?.loginAt,
          };
        });
      }

      dispatch(setAllUsers({ usersList: users.results, actionType }))
      dispatch(
        setUsers({ data: users, status: DATA_FETCHING_STATUSES.DATA_LOADED })
      );
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      console.log("fetch user thunk error=>>>", error)
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }
);

export const fetchSingleUserThunk = createAsyncThunk(
  "appAuth/fetchSingleUser",
  async ({ id }, { dispatch, getState }) => {
    try {
      const state = getState().authReducer.users;
      const { status, ...restState } = state;
      const response = await axiosPrivate.get(`${ENDPOINTS.USERS}/${id}`);
      const user = response?.data?.user;

      let newUsers = [];
      if (restState.data.results.length === 0) {
        newUsers = [...restState.data.results, user];
      } else {
        newUsers = restState.data.results.map((u) => {
          if (u.id === id) {
            return user;
          }
          return u;
        });
      }

      const updatedUsers = {
        ...restState,
        data: {
          ...restState.data,
          results: newUsers,
        },
      };

      dispatch(
        setUsers({
          data: updatedUsers.data,
          status: DATA_FETCHING_STATUSES.DATA_LOADED,
        })
      );
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }
);

export const fetchAchievementsThunk = createAsyncThunk(
  "appAuth/fetchAchievements",
  async ({ page, userId }, { dispatch }) => {
    try {
      const response = await axiosPrivate.get(
        `${ENDPOINTS.REWARDS}/${userId}/byuser`,
        {
          params: {
            page,
            // userId,
          },
        }
      );
      const achievements = response.data;
      dispatch(
        setAchievements({
          data: achievements,
          status: DATA_FETCHING_STATUSES.DATA_LOADED,
        })
      );
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }
);

export const fetchGameEntryFeeThunk = createAsyncThunk(
  "appAuth/fetchGameEntryFee",
  async ({limit, page }, { dispatch }) => {
    try {
      const response = await axiosPrivate.get(`${ENDPOINTS.GAME_ENTRY_FEE}`, { params: { limit, page } });
      const settings = response.data;
      dispatch(
        setGameSettings({
          data: settings,
          status: DATA_FETCHING_STATUSES.DATA_LOADED,
        })
      );
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }
);

export const sendPushNotification = createAsyncThunk(
  "appAuth/sendPushNotification",
  async ({ title, message }, { dispatch }) => {
    try {
      const response = await axiosPrivate.post(`${ENDPOINTS.PUSH_NOTIFICATIONS}`, {
        title,
        message,
      });
      const notification = response.data;
      dispatch(
        setPushNotifications({
          data: notification,
        })
      );
      toast.success("Push Notification send successfully");
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }
);

export const updateGameEntryFeeThunk = createAsyncThunk(
  "appAuth/updateGameEntryFee",
  async ({ entryFee, goldenAcornsLimit,startDate,endDate }, { dispatch }) => {
    try {
      const response = await axiosPrivate.post(`${ENDPOINTS.GAME_ENTRY_FEE}`, {
        entryFee,
        goldenAcornsLimit,
        startDate,
        endDate,
      });
      const settings = response.data;
      dispatch(
        setGameSettings({
          data: settings,
          status: DATA_FETCHING_STATUSES.DATA_LOADED,
        })
      );
      toast.success("Game settings successfully added");
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }
);

export const DEFAULT_ENTRY_FEE = 5;
const initialState = {
  gameSettings: {
    data: {
      entryFee: DEFAULT_ENTRY_FEE,
    },
    status: DATA_FETCHING_STATUSES.DATA_LOADING,
  },
  isFileUploading: false,
  user: {
    status: DATA_FETCHING_STATUSES.DATA_LOADING,
    data: null,
  },
  users: {
    status: DATA_FETCHING_STATUSES.DATA_LOADING,
    data: {
      status: 200,
      totalPages: 1,
      totalResults: 5,
      results: [],
      page: 1,
      limit: 10,
    },
  },
  achievements: {
    status: DATA_FETCHING_STATUSES.DATA_LOADING,
    data: [],
  },
  allUsers: [],
  pushNotifications: [],
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setGameSettings: (state, action) => {
      state.gameSettings = action.payload;
    },
    setIsFileUploading: (state, action) => {
      state.isFileUploading = action.payload;
    },
    setUsers: (state, action) => {
      state.users = {
        ...state.users,
        ...action.payload,
      };
    },
    setUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    setResetUser: (state) => {
      state.user = initialState.user;
    },
    setAchievements: (state, action) => {
      state.achievements = {
        ...state.achievements,
        ...action.payload,
      };
    },
    setPushNotifications: (state, action) => {
      state.pushNotifications = action.payload;
    },
  },
});
export const {
  setResetUser,
  setUser,
  setUsers,
  setAchievements,
  setIsFileUploading,
  setGameSettings,
  setPushNotifications,
} = authSlice.actions;

export default authSlice.reducer;

//Selector
export const useAuthSelector = () =>
  useSelector((state) => {
    return state.authReducer;
  });

export const useIsFileUploadingSelector = () =>
  useSelector((state) => {
    return state.authReducer.isFileUploading;
  });

export const useGameSettingsSelector = () =>
  useSelector((state) => {
    return state.authReducer.gameSettings;
  });
  
  export const usePushNotficationSelector = () =>
    useSelector((state) => {
      return state.authReducer.pushNotifications;
});
