import {
  Box,
  Input,
  chakra,
  Flex,
  Select,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { format } from "date-fns";
import { Controller, useFormContext } from "react-hook-form";
import { CustomFormLabel } from "./Input";
import { formatPayloadDate } from "lib/helpers";

const PlaceholderInput = chakra(Input, {
  baseStyle: {
    display: "none",
    color: "white",
    fontSize: 14,
    cursor: "pointer",
  },
});

const StyledInput = chakra(Input, {
  baseStyle: {
    // display: "none",
    // color: "white",
    // fontSize: 14,
    // cursor: "pointer",
  },
});

export function CustomDatePicker({ selectedDate, handleSelectDate }) {
  const [open, setOpen] = useState(false);

  return (
    <Box>
      <Box
        onClick={() => setOpen((prev) => !prev)}
        sx={{
          px: 2,
          py: 1,
          color: "white",
          borderRadius: "md",
          background: "#dd9122",
          cursor: "pointer",
          fontSize: "14px",
        }}
      >
        <Flex gap={2}>
          <Box fontWeight="bold">
            {selectedDate
              ? format(selectedDate, "MMM dd, yyyy")
              : "Select Date"}
          </Box>
          <Box>{open ? <ChevronUpIcon /> : <ChevronDownIcon />}</Box>
        </Flex>
      </Box>
      <DatePicker
        open={open}
        selected={selectedDate}
        onChange={handleSelectDate}
        onClickOutside={() => {
          setOpen(false);
        }}
        customInput={<PlaceholderInput />}
      />
    </Box>
  );
}

export function CustomFormDatePicker({ label, name }) {
  const {
    control,
    formState: { errors },
  } = useFormContext(); // Ensures this component can be used in a form context

  return (
    <FormControl isInvalid={errors[name]}>
      <Box mb="24px">
        <CustomFormLabel label={label ?? ""} />
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              minDate={new Date()}
              selected={new Date(value)}
              onChange={(val) => onChange(formatPayloadDate(val))}
              customInput={
                <Input
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  fontWeight="500"
                  size="lg"
                  _placeholder={{ color: "grey.700" }}
                  borderColor="brand.950"
                  color="brand.950"
                />
              }
            />
          )}
        />

        {/* Error Message */}
        <FormErrorMessage>
          {errors?.[name] && errors?.[name]?.message}
        </FormErrorMessage>
      </Box>
    </FormControl>
  );
}
