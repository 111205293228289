import React, { useEffect } from "react";
import {
  FormControl,
  Box,
  Flex,
  Heading,
  Text,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import { useAuth } from "contexts/AuthContext";
import { useAuthSelector } from "store/slices/auth";
import { useDispatch } from "react-redux";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Card from "components/card/Card";
import { PrimayButton } from "components/customs/buttons";
import { useProtectedAdminRoutes } from "lib/hooks";
import { CustomFormInput } from "components/customs/Input";
import {
  fetchGameEntryFeeThunk,
  updateGameEntryFeeThunk,
  useGameSettingsSelector,
} from "store/slices/auth";
import { DEFAULT_ENTRY_FEE } from "store/slices/auth";
import { DATA_FETCHING_STATUSES } from "lib/constants";

const schema = yup.object().shape({
  entryFee: yup
    .number()
    .typeError("Only numbers are allowed for game entry fee.")
    .integer("Decimal numers are not allowed.")
    .min(1, "Game entry fee cannot be less than 1.")
    .max(30, "Game entry fee cannot be greater than 30.")
    .required("Game entry fee is required."),
});

function GameSettings() {
  // useProtectedAdminRoutes();
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "#D0BEA3";

  const dispatch = useDispatch();
  const auth = useAuthSelector();
  const settingsState = useGameSettingsSelector();
  const entryFee = settingsState?.data?.settings?.entryFee ?? DEFAULT_ENTRY_FEE;
  const history = useHistory();

  const methods = useForm({
    defaultValues: {
      entryFee,
    },
    resolver: yupResolver(schema),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = methods;

  console.log("isDirty=>>", isDirty);
  const onSubmit = async (data) => {
    const res = await dispatch(
      updateGameEntryFeeThunk({ entryFee: data?.entryFee })
    );
    if (res.payload === API_SUCCESS_RESPONSE_TAG) {
    }
  };

  useEffect(() => {
    dispatch(fetchGameEntryFeeThunk({}));
  }, []);

  function handleReset() {
    reset({
      entryFee: entryFee ?? DEFAULT_ENTRY_FEE,
    });
  }

  useEffect(() => {
    handleReset();
  }, [settingsState]);

  if (settingsState?.status !== DATA_FETCHING_STATUSES.DATA_LOADED) {
    return (
      <Flex justifyContent="center" mt="28">
        <Spinner size="xl" color="brand.950" />
      </Flex>
    );
  }
  return (
    // <DefaultAuth illustrationBackground={illustration} image={illustration}>
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        mb={{ base: "0px", lg: "20px" }}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Heading
            textAlign="center"
            color={textColor}
            fontSize="36px"
            mb="10px"
          >
            Game Entry Fee
          </Heading>
          <Text
            // textAlign="center"
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter the game entry fee.
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Flex align="center" mb="25px">
            <HSeparator />
          </Flex>
          <FormProvider {...methods}>
            <fieldset disabled={isSubmitting}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl isInvalid={errors.entryFee}>
                  <Box mb="24px">
                    <CustomFormInput
                      {...{
                        placeholder: "Enter the game entry fee",
                        type: "text",
                        name: "entryFee",
                        label: "Game Entry Fee",
                        isRequired: true,
                      }}
                    />
                  </Box>
                  {/* <Flex justifyContent="center"> */}
                  <PrimayButton
                    disabled={!isDirty}
                    label="Update"
                    isLoading={isSubmitting}
                    w="100%"
                  />
                </FormControl>
              </form>
            </fieldset>
          </FormProvider>
        </Flex>
      </Card>
    </Box>
    // </DefaultAuth>
  );
}

export default GameSettings;
