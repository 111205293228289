import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdLock,
  MdOutlineAdminPanelSettings,
} from "react-icons/md";
import { GrAchievement } from "react-icons/gr";
import { FaGamepad } from "react-icons/fa";
import { IoIosSettings } from "react-icons/io";

// Admin Imports
import AdminProfile from "views/admin/admin-profile";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignUp from "views/auth/signUp";
import Welcome from "views/auth/welcome";
import { Users } from "views/admin/users";
import { User } from "views/admin/users/user";
import { Achievements } from "views/admin/achievements";
import { Leaderboard } from "views/admin/leaderboard";
import { Rewards } from "views/admin/rewards";
import { ForgotPassword } from "views/admin/forgot-password";
import { ResetPassword } from "views/admin/reset-password";
import InviteAdmin from "views/admin/invite";
import Chat from "views/admin/chat";
import GameSettings from "views/admin/gameSettings";
import { Notifications } from "views/admin/notifications";
import { ChatIcon, EmailIcon, SettingsIcon } from "@chakra-ui/icons";

const routes = [
  // Hidden Routes - Routes which are the avaialble but not in the sidebar
  {
    name: "Notifications",
    layout: "/admin",
    icon: (
      <Icon
        as={MdOutlineAdminPanelSettings}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/notifications",
    component: Notifications,
    hide: true,
  },
  {
    name: "Welcome",
    layout: "/auth",
    path: "/welcome",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Welcome,
    hide: true,
  },
  {
    name: "Admin Profile",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/admin-profile",
    component: AdminProfile,
    hide: true,
  },
  {
    name: "Player",
    layout: "/admin",
    icon: (
      <Icon
        as={MdOutlineAdminPanelSettings}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/players/edit",
    component: User,
    hide: true,
  },
  {
    name: "Admin",
    layout: "/admin",
    icon: (
      <Icon
        as={MdOutlineAdminPanelSettings}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/admins/view",
    component: User,
    hide: true,
  },
  {
    name: "Admin",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/admins/edit",
    component: User,
    hide: true,
  },
  {
    name: "Player",
    layout: "/admin",
    icon: <Icon as={FaGamepad} width="20px" height="20px" color="inherit" />,
    path: "/players/view",
    component: User,
    hide: true,
  },
  {
    name: "Achievements",
    layout: "/admin",
    icon: (
      <Icon as={GrAchievement} width="16px" height="16px" color="inherit" />
    ),
    path: "/players/achievements",
    component: Achievements,
    hide: true,
  },
  // Sidebar routes - Routes who are visible in sidebar

  {
    name: "Game Settings",
    layout: "/admin",
    path: "/game-settings",
    icon: (
      <Icon as={IoIosSettings} width="18px" height="18px" color="inherit" />
    ),
    component: GameSettings,
  },
  {
    name: "Invite Admin",
    layout: "/admin",
    path: "/invite-admin",
    icon: <Icon as={EmailIcon} width="20px" height="20px" color="inherit" />,
    component: InviteAdmin,
  },
  {
    name: "Leaderboard",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/leaderboard",
    component: Leaderboard,
    hide: false,
  },
  {
    name: "Players",
    layout: "/admin",
    icon: <Icon as={FaGamepad} width="20px" height="20px" color="inherit" />,
    path: "/players",
    component: Users,
    hide: false,
  },
  {
    name: "Admins",
    layout: "/admin",
    icon: (
      <Icon
        as={MdOutlineAdminPanelSettings}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/admins",
    component: Users,
    hide: false,
  },
  {
    name: "Rewards",
    layout: "/admin",
    icon: (
      <Icon as={GrAchievement} width="16px" height="16px" color="inherit" />
    ),
    path: "/rewards",
    component: Rewards,
    hide: false,
  },
  {
    name: "Chat",
    layout: "/admin",
    icon: <Icon as={ChatIcon} width="16px" height="16px" color="inherit" />,
    path: "/chat",
    component: Chat,
    hide: false,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ForgotPassword,
    hide: true,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "/reset-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ResetPassword,
    hide: true,
  },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   path: "/data-tables",
  //   component: DataTables,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   component: Profile,
  // },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignUp,
  },
  // {
  //   name: "Reset Password",
  //   layout: "/auth",
  //   path: "/reset-password",
  //   icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
  //   component: ResetPassword,
  // },
];

export default routes;
