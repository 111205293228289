import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import {
  Avatar,
  Box,
  Flex,
  Grid,
  GridItem,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import avatar from "assets/img/avatars/avatar4.png";
import Card from "components/card/Card";
import { HSeparator } from "components/separator/Separator";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomFormInput } from "components/customs/Input";
import { PrimayButton, UploadButton } from "components/customs/buttons";
import { useColors } from "components/customs/colors";
import { useFormSchema } from "./schema";
import { updateProfileThunk, useAuthSelector } from "store/slices/auth";
import { BACKEND_BASE_URL } from "lib/constants";
import { fetchSingleUserThunk } from "store/slices/auth";
import { CustomHeading } from "components/customs/heading";
import { useIsFileUploadingSelector } from "store/slices/auth";
import { BackButton } from "components/customs/backButton";

export function useSearchParams() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  return { searchParams };
}

export function User() {
  const isUploading = useIsFileUploadingSelector();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const auth = useAuthSelector();
  const { searchParams } = useSearchParams();
  const role = searchParams.get("role");
  const isAdmin = role?.toLowerCase() === "admins";
  const id = searchParams.get("id");
  const action = history?.location?.pathname?.split("/")?.at(-1);
  const isEditMode = action === "edit";
  const users = auth?.users;
  const current_user_id = id;
  const user = users?.data?.results?.find(
    (user) => user.id === current_user_id
  );

  useEffect(() => {
    dispatch(fetchSingleUserThunk({ id: current_user_id }));
  }, []);

  const { borderColor, textColor } = useColors();

  const defaultValues = {
    // email: user?.email ?? "",
    rank: user?.rank ?? "",
    highest_score: user?.highest_score ?? "",
    password: "",
    confirmPassword: "",
    username: user?.username ?? "",
    acorns: user?.acorns ?? 0,
    golden_acorns: user?.golden_acorns ?? 0,
    x2CollectedUsed: user?.x2CollectedUsed ?? 0,
    golden_acorns_used: user?.golden_acorns_used ?? 0,
    x3CollectedUsed: user?.x3CollectedUsed ?? 0,
    x2Collected: user?.x2Collected ?? 0,
    x3Collected: user?.x3Collected ?? 0,
  };

  const schema = useFormSchema({ isAdmin });
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  function handleReset() {
    reset({
      password: "",
      confirmPassword: "",
      email: user?.email ?? "",
      rank: user?.rank ?? "N/A",
      highest_score: user?.highest_score ?? "",
      username: user?.username ?? "",
      acorns: user?.acorns ?? 0,
      x2CollectedUsed: user?.x2CollectedUsed ?? 0,
      golden_acorns_used: user?.golden_acorns_used ?? 0,
      x3CollectedUsed: user?.x3CollectedUsed ?? 0,
      golden_acorns: user?.golden_acorns ?? 0,
      x2Collected: user?.x2Collected ?? 0,
      x3Collected: user?.x3Collected ?? 0,
    });
  }

  useEffect(() => {
    handleReset();
  }, [auth]);

  const {
    setError,
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty, dirtyFields },
  } = methods;

  const onSubmit = async (data) => {
    if (!isDirty) return;
    let payload = Object.keys(data).reduce((acc, current) => {
      if (dirtyFields[current]) {
        acc[current] = data[current];
      }
      return acc;
    }, {});

    if (isAdmin) {
      const { name, email, username } = payload;
      payload = { name, email, username };
    }

    const res = await dispatch(
      updateProfileThunk({ ...payload, user_id: id ?? "" })
    );
    if (res.payload === API_SUCCESS_RESPONSE_TAG) {
      handleReset();
    }
  };

  function toggleEditMode() {
    history.push(`${location.pathname}/edit?id=${id}&role=${role}`);
  }

  const password = watch("password");
  useEffect(() => {
    if (password?.length === 0) {
      setError("confirmPassword", {
        type: "manual",
        message: "",
      });
    }
  }, [password]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card mb={{ base: "0px", lg: "20px" }} align="left">
        <BackButton />

        <Flex
          flexDirection="column"
          justifyContent={"center"}
          alignItems="center"
        >
          <UploadButton
            label="Upload"
            currentUser={user}
            showUploadBtn={isEditMode}
          />
        </Flex>

        <Flex align="center" my="25px">
          <HSeparator />
        </Flex>

        <FormProvider {...methods}>
          <fieldset disabled={!(isEditMode || isDirty)}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  lg: "repeat(2, 1fr)",
                }}
                gap={{ base: 1, lg: 2 }}
              >
                <GridItem mb={1}>
                  <CustomFormInput
                    {...{
                      placeholder: "Enter your username",
                      type: "text",
                      name: "username",
                      label: "Username",
                      isRequired: true,
                    }}
                  />
                </GridItem>
                <GridItem mb={1}>
                  <fieldset disabled={true} style={{ opacity: 0.6 }}>
                    <CustomFormInput
                      {...{
                        placeholder: "Enter your email",
                        type: "email",
                        name: "email",
                        label: "Email",
                        isRequired: true,
                      }}
                    />
                  </fieldset>
                </GridItem>

                {!isAdmin && (
                  <>
                    <GridItem mb={1}>
                      <fieldset disabled={true} style={{ opacity: 0.6 }}>
                        <CustomFormInput
                          {...{
                            placeholder: "Player's Rank",
                            type: "text",
                            name: "rank",
                            label: "Current Rank",
                            isRequired: true,
                          }}
                        />
                      </fieldset>
                    </GridItem>
                    <GridItem mb={1}>
                      <fieldset disabled={true} style={{ opacity: 0.6 }}>
                        <CustomFormInput
                          {...{
                            placeholder: "Player's highest score",
                            type: "text",
                            name: "highest_score",
                            label: "Highest Score",
                            isRequired: true,
                          }}
                        />
                      </fieldset>
                    </GridItem>

                    <GridItem mb={1}>
                      <CustomFormInput
                        {...{
                          placeholder: "Player's Acorns",
                          type: "text",
                          name: "acorns",
                          label: "Acorns",
                          isRequired: true,
                        }}
                      />
                    </GridItem>
                    <GridItem mb={1}>
                      <CustomFormInput
                        {...{
                          placeholder: "Player's golden acorns",
                          type: "text",
                          name: "golden_acorns",
                          label: "Golden Acorns",
                          isRequired: true,
                        }}
                      />
                    </GridItem>

                    <GridItem mb={1}>
                      <fieldset disabled={true} style={{ opacity: 0.6 }}>
                        <CustomFormInput
                          {...{
                            placeholder: "X2 Collected",
                            type: "text",
                            name: "x2Collected",
                            label: "X2 Collected",
                            isRequired: true,
                          }}
                        />
                      </fieldset>
                    </GridItem>
                    <GridItem mb={1}>
                      <fieldset disabled={true} style={{ opacity: 0.6 }}>
                        <CustomFormInput
                          {...{
                            placeholder: "X3 Collected",
                            type: "text",
                            name: "x3Collected",
                            label: "X3 Collected",
                            isRequired: true,
                          }}
                        />
                      </fieldset>
                    </GridItem>

                    <GridItem mb={1}>
                      <fieldset disabled={true} style={{ opacity: 0.6 }}>
                        <CustomFormInput
                          {...{
                            placeholder: "X2 Spins",
                            type: "text",
                            name: "x2CollectedUsed",
                            label: "X2 Spins",
                            isRequired: true,
                          }}
                        />
                      </fieldset>
                    </GridItem>
                    <GridItem mb={1}>
                      <fieldset disabled={true} style={{ opacity: 0.6 }}>
                        <CustomFormInput
                          {...{
                            placeholder: "X3 Spins",
                            type: "text",
                            name: "x3CollectedUsed",
                            label: "X3 Spins",
                            isRequired: true,
                          }}
                        />
                      </fieldset>
                    </GridItem>

                    <GridItem colSpan={2} mb={1}>
                      <fieldset disabled={true} style={{ opacity: 0.6 }}>
                        <CustomFormInput
                          {...{
                            placeholder: "Total Spins",
                            type: "text",
                            name: "golden_acorns_used",
                            label: "Total Spins",
                            isRequired: true,
                          }}
                        />
                      </fieldset>
                    </GridItem>
                  </>
                )}
              </Grid>

              <Box>
                <Box my="3">
                  <CustomHeading text="Change Password" />
                </Box>
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    lg: "repeat(2, 1fr)",
                  }}
                  gap={3}
                >
                  <GridItem>
                    <CustomFormInput
                      {...{
                        placeholder: "Enter your new password",
                        type: "password",
                        name: "password",
                        label: "Password",
                        isRequired: true,
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <CustomFormInput
                      {...{
                        placeholder: "Confirm your new password",
                        type: "password",
                        name: "confirmPassword",
                        label: "Confirm Password",
                        isRequired: true,
                      }}
                    />
                  </GridItem>
                </Grid>
              </Box>
              {isEditMode && (
                <Flex justifyContent="center">
                  <PrimayButton
                    key="submit"
                    label="Update"
                    isLoading={isSubmitting}
                    disabled={!isDirty}
                  />
                </Flex>
              )}
            </form>
          </fieldset>
          {!isEditMode && (
            <Flex justifyContent="center">
              <PrimayButton
                key="button"
                type="button"
                label="Edit"
                onClick={toggleEditMode}
                disabled={false}
                isLoading={isSubmitting}
              />
            </Flex>
          )}
        </FormProvider>
      </Card>
    </Box>
  );
}
