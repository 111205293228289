import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  IconButton,
  Link,
  HStack,
  Button,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
// import { Box, Button, HStack, IconButton, Text } from "@chakra-ui/react";
// import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useState, useMemo } from "react";

export const Pagination = ({
  currentPage,
  totalPages,
  handleNext,
  handlePrevious,
}) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
      <IconButton
        icon={<ChevronLeftIcon />}
        onClick={handlePrevious}
        disabled={currentPage === 1}
        mr={2}
        aria-label="Previous page"
        _hover={{ opacity: 0.8 }}
        bg="#C97800"
        color="#FFFFFF"
        fontWeight="bold"
      />
      <Text fontWeight="bold" mx={2} color="#C97800">
        Page {currentPage} of {totalPages}
      </Text>
      <IconButton
        icon={<ChevronRightIcon />}
        onClick={handleNext}
        disabled={currentPage === totalPages}
        ml={2}
        aria-label="Next page"
        _hover={{ opacity: 0.8 }}
        bg="#C97800"
        color="#FFFFFF"
        fontWeight="bold"
      />
    </Box>
  );
};

// export const Pagination = ({
//   handleNext,
//   handlePrevious,
//   handleSelectPage,
//   total,
//   siblings = 1,
//   defaultValue = 10,
//   currentPage,
// }) => {
//   // Calculate total number of pages
//   const totalPages = Math.ceil(total / defaultValue);

//   // Generate an array of page numbers
//   const generatePageNumbers = () => {
//     const pages = [];
//     let startPage = Math.max(1, currentPage - siblings);
//     let endPage = Math.min(totalPages, currentPage + siblings);

//     for (let i = startPage; i <= endPage; i++) {
//       pages.push(i);
//     }

//     return pages;
//   };

//   return (
//     <Box
//       display="flex"
//       justifyContent="space-between"
//       alignItems="center"
//       mt={4}
//     >
//       {/* Previous Button */}
//       <IconButton
//         onClick={handlePrevious}
//         icon={<ChevronLeftIcon />}
//         aria-label="Previous"
//         isDisabled={currentPage === 1}
//         variant="outline"
//         colorScheme="gray"
//       />

//       {/* Page numbers */}
//       <HStack spacing={2}>
//         {generatePageNumbers().map((page) => (
//           <Button
//             key={page}
//             size="sm"
//             bg={page === currentPage ? "indigo.600" : "white"}
//             color={page === currentPage ? "white" : "gray.900"}
//             _hover={{ bg: page === currentPage ? "indigo.500" : "gray.50" }}
//             onClick={() => handleSelectPage(page)}
//           >
//             {page}
//           </Button>
//         ))}
//       </HStack>

//       {/* Next Button */}
//       <IconButton
//         onClick={handleNext}
//         icon={<ChevronRightIcon />}
//         aria-label="Next"
//         isDisabled={currentPage === totalPages}
//         variant="outline"
//         colorScheme="gray"
//       />
//     </Box>
//   );
// };

// export const Pagination = ({
//   total,
//   siblings = 1,
//   defaultValue = 10,
//   currentPage,
//   handleSelectPage,
//   handleNext,
//   handlePrevious,
// }) => {
//   const totalPages = Math.ceil(total / defaultValue);

//   return (
//     <Box
//       display="flex"
//       justifyContent="space-between"
//       alignItems="center"
//       mt={4}
//     >
//       {/* Previous Button */}
//       <IconButton
//         onClick={handlePrevious}
//         icon={<ChevronLeftIcon />}
//         aria-label="Previous"
//         isDisabled={currentPage === 1}
//         variant="outline"
//         colorScheme="gray"
//       />

//       {/* Page Numbers */}
//       <HStack spacing={2}>
//         {siblings.map((page, index) => (
//           <Button
//             key={index}
//             size="sm"
//             bg={page === currentPage ? "indigo.600" : "white"}
//             color={page === currentPage ? "white" : "gray.900"}
//             _hover={{ bg: page === currentPage ? "indigo.500" : "gray.50" }}
//             onClick={() => handleSelectPage(page)}
//           >
//             {page}
//           </Button>
//         ))}
//       </HStack>

//       {/* Next Button */}
//       <IconButton
//         onClick={handleNext}
//         icon={<ChevronRightIcon />}
//         aria-label="Next"
//         isDisabled={currentPage === totalPages}
//         variant="outline"
//         colorScheme="gray"
//       />
//     </Box>
//   );
// };

// export const usePagination = ({ total, initialPage = 1, siblings = 1 }) => {
//   const [currentPage, setCurrentPage] = useState(initialPage);

//   // Function to get the pagination range
//   const paginationRange = useMemo(() => {
//     const totalPageNumbers = siblings * 2 + 5;

//     if (totalPageNumbers >= total) {
//       return [...Array(total).keys()].map((n) => n + 1);
//     }

//     const leftSiblingIndex = Math.max(currentPage - siblings, 1);
//     const rightSiblingIndex = Math.min(currentPage + siblings, total);

//     const shouldShowLeftDots = leftSiblingIndex > 2;
//     const shouldShowRightDots = rightSiblingIndex < total - 2;

//     const firstPageIndex = 1;
//     const lastPageIndex = total;

//     if (!shouldShowLeftDots && shouldShowRightDots) {
//       let leftRange = [...Array(3 + 2 * siblings).keys()].map((n) => n + 1);
//       return [...leftRange, "dots", total];
//     } else if (shouldShowLeftDots && !shouldShowRightDots) {
//       let rightRange = [...Array(3 + 2 * siblings).keys()]
//         .map((n) => total - siblings * 2 - (3 - n))
//         .filter((n) => n > 0);
//       return [firstPageIndex, "dots", ...rightRange];
//     } else if (shouldShowLeftDots && shouldShowRightDots) {
//       let middleRange = [...Array(3).keys()].map((n) => currentPage - 1 + n);
//       return [firstPageIndex, "dots", ...middleRange, "dots", lastPageIndex];
//     }
//   }, [total, currentPage, siblings]);

//   const setPage = (page) => {
//     if (page >= 1 && page <= total) {
//       setCurrentPage(page);
//     }
//   };

//   const next = () => {
//     if (currentPage < total) {
//       setCurrentPage((prev) => prev + 1);
//     }
//   };

//   const previous = () => {
//     if (currentPage > 1) {
//       setCurrentPage((prev) => prev - 1);
//     }
//   };

//   return {
//     currentPage,
//     range: paginationRange,
//     setPage,
//     next,
//     previous,
//   };
// };
