import {
  Flex,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
// Custom components
import Card from "components/card/Card";
import { Pagination } from "components/customs/pagination";
import { CustomNoDataFound } from "components/customs/noDataFound";
import { CustomDatePicker } from "components/customs/datePicker";
import { PrimayButton } from "components/customs/buttons";
import { RewardModal } from "./modal";
import { useMediaQuery } from "@chakra-ui/react";
import { BulkCreateRewardModal } from "./bulkCreateRewardModal";
import { MdOutlineCloudUpload } from "react-icons/md";

const TooltipWrapper = React.forwardRef(
  ({ label, iconProps, ...rest }, ref) => (
    <Tooltip label={label} hasArrow>
      <div ref={ref}>
        <Icon {...iconProps} {...rest} />
      </div>
    </Tooltip>
  )
);

export function RewardsTable(props) {
  const {
    columnsData,
    tableData,
    totalPages,
    currentPage,
    setCurrentPage,
    selectedDate,
    handleSelectDate,
    isOpen,
    onOpen,
    onClose,
  } = props;
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const tableInstance = useTable(
    {
      columns: columnsData,
      data: tableData,
      manualPagination: true, // Use manual pagination to handle API
      pageCount: totalPages,
      state: { pageIndex: currentPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
  } = tableInstance;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedDate]);

  function handleNext() {
    if (canNextPage) {
      setCurrentPage((prev) => prev + 1);
      nextPage();
    }
  }

  function handlePrevious() {
    if (!canPreviousPage) {
      setCurrentPage((prev) => prev - 1);
      previousPage();
    }
  }

  return (
    <>
      {isOpen && (
        <RewardModal
          {...{ isOpen, onOpen, onClose, selectedDate, handleSelectDate }}
        />
      )}
      <Card direction="column" w="100%" px="0px">
        <Flex px="25px" gap={5} align="start" justifyContent="space-between">
          <Flex gap={5} wrap="wrap">
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
            </Text>
          </Flex>
          <HStack spacing={"6"} alignItems="flex-start">
           
            <PrimayButton
              key="button"
              type="button"
              label={isMobile ? "Add New" : "Add New"}
              onClick={onOpen}
            />
          </HStack>
        </Flex>
        <TableContainer>
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          <Flex align="center">
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell.value}
                            </Text>
                          </Flex>
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {totalPages === 0 ? (
            <CustomNoDataFound />
          ) : (
            <Pagination
              {...{
                currentPage,
                totalPages,
                handleNext,
                handlePrevious,
              }}
            />
          )}
        </TableContainer>
      </Card>
    </>
  );
}
