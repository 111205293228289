// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { CHAT_USERS_ACTION_TYPE } from "./auth";

const initialState = {
  allUsers: [],
};

export const chatUsersSlice = createSlice({
  name: "chatUsersSlice",
  initialState,
  reducers: {
    setAllUsers: (state, action) => {
      if (action.payload.actionType === CHAT_USERS_ACTION_TYPE) {
        state.allUsers = action.payload.usersList;
      } else {
        const prevUsers = state.allUsers;
        const usersList = action.payload.usersList;
        const updatedUsers = [...prevUsers, ...usersList];
        const uniqueUsers = new Map(
          updatedUsers.map((user) => [user.id, user])
        );
        state.allUsers = Array.from(uniqueUsers.values());
      }
    },
    setUpdateChatCount: (state, action) => {
      const allUsers = state.allUsers;
      const senderId = action.payload.senderId;
      state.allUsers = allUsers.map((user) => {
        if (user.id === senderId) {
          return { ...user, unreadChats: user.unreadChats + 1 };
        }
        return user;
      });
    },
  },
});

export const { setAllUsers, setUpdateChatCount } = chatUsersSlice.actions;

export default chatUsersSlice.reducer;

export const useChatUsersSelector = () =>
  useSelector((state) => {
    return state.chatUsersReducer.allUsers;
  });
