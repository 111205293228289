export const notificationsColumns = [
  {
    Header: "Notification Message",
    accessor: "message",
  },
  {
    Header: "Date",
    accessor: "date",
  },
];
