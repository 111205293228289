// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.css-1dt04rm {
  background: rgba(254, 249, 244, 1) !important;
}

/* Global Scrollbar Styling for Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scrollbar track (background) */
}

::-webkit-scrollbar-thumb {
  background-color: #dd9224; /* Scrollbar thumb (handle) color */
  border-radius: 10px; /* Round the thumb */
  border: 3px solid #f1f1f1; /* Adds a border around the thumb */
}

/* Global Scrollbar Styling for Firefox */
* {
  scrollbar-width: thin; /* Set scrollbar width */
  scrollbar-color: #dd9224 #f1f1f1; /* Thumb color, Track color */
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/App.css"],"names":[],"mappings":"AACA;EACE,kCAAkC;AACpC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,6CAA6C;AAC/C;;AAEA,0DAA0D;AAC1D;EACE,WAAW,EAAE,mCAAmC;AAClD;;AAEA;EACE,mBAAmB,EAAE,iCAAiC;AACxD;;AAEA;EACE,yBAAyB,EAAE,mCAAmC;EAC9D,mBAAmB,EAAE,oBAAoB;EACzC,yBAAyB,EAAE,mCAAmC;AAChE;;AAEA,yCAAyC;AACzC;EACE,qBAAqB,EAAE,wBAAwB;EAC/C,gCAAgC,EAAE,6BAA6B;AACjE","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap\");\nbody {\n  font-family: \"DM Sans\", sans-serif;\n}\n\noption {\n  color: black;\n}\n\n.css-1dt04rm {\n  background: rgba(254, 249, 244, 1) !important;\n}\n\n/* Global Scrollbar Styling for Chrome, Edge, and Safari */\n::-webkit-scrollbar {\n  width: 12px; /* Set the width of the scrollbar */\n}\n\n::-webkit-scrollbar-track {\n  background: #f1f1f1; /* Scrollbar track (background) */\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #dd9224; /* Scrollbar thumb (handle) color */\n  border-radius: 10px; /* Round the thumb */\n  border: 3px solid #f1f1f1; /* Adds a border around the thumb */\n}\n\n/* Global Scrollbar Styling for Firefox */\n* {\n  scrollbar-width: thin; /* Set scrollbar width */\n  scrollbar-color: #dd9224 #f1f1f1; /* Thumb color, Track color */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
