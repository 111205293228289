import { useColorModeValue } from "@chakra-ui/system";

export function useColors() {
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  const textColor = useColorModeValue("secondaryGray.700", "white");

  return { borderColor, textColor };
}
