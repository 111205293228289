// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DATA_FETCHING_STATUSES } from "lib/constants";
import axiosPrivate from "lib/config/axios";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import { ENDPOINTS } from "lib/constants";

function getNotificationQueryParam({ notificationType }) {
  if (notificationType === "all") return null;
  if (notificationType === "read") return { isRead: true };
  if (notificationType === "unread") return { isRead: false };
  return false;
}

export const fetchNotificationsThunk = createAsyncThunk(
  "appNotifications/fetchNotifications",
  async ({ page, notificationType }, { dispatch }) => {
    try {
      const response = await axiosPrivate.get(`${ENDPOINTS.NOTIFICATIONS}`, {
        params: {
          page,
          sortBy: "notificationDate:desc",
          ...getNotificationQueryParam({ notificationType }),
        },
      });
      const notifications = response.data;
      dispatch(
        setNotifications({
          data: notifications,
          status: DATA_FETCHING_STATUSES.DATA_LOADED,
        })
      );
      return {
        notifications,
        API_SUCCESS_RESPONSE_TAG,
      };
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Something went wrong");
    }
  }
);

export const readSingleNotificationThunk = createAsyncThunk(
  "appNotifications/readSingleNotification",
  async (
    { notificationId, page, notificationType },
    { dispatch, getState }
  ) => {
    try {
      const state = getState();
      const unReadNotificationsCount =
        state.notificationsReducer.unReadNotificationsCount;
      await axiosPrivate.patch(`${ENDPOINTS.NOTIFICATIONS}`, {
        notificationId,
      });

      if (unReadNotificationsCount > 0) {
        dispatch(
          setNotificationsCount({
            count: unReadNotificationsCount - 1,
          })
        );
      }

      dispatch(
        fetchNotificationsThunk({
          page,
          notificationType,
        })
      );
      toast.success("Notification has been marked as read");
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Something went wrong");
    }
  }
);

export const readAllNotificationsThunk = createAsyncThunk(
  "appNotifications/readAllNotifications",
  async ({ page, notificationType }, { dispatch }) => {
    try {
      await axiosPrivate.patch(
        `${ENDPOINTS.NOTIFICATIONS}/mark-all-as-read`,
        {}
      );
      dispatch(
        setNotificationsCount({
          count: 0,
        })
      );
      dispatch(
        fetchNotificationsThunk({
          page,
          notificationType,
        })
      );
      toast.success("All notification have been marks as read");
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Something went wrong");
    }
  }
);

export const loadNotificationsCountThunk = createAsyncThunk(
  "appNotifications/loadNotificationsCount",
  async ({}, { dispatch }) => {
    try {
      const response = await axiosPrivate.get(`${ENDPOINTS.NOTIFICATIONS}`, {
        params: {
          page: 1,
          sortBy: "notificationDate:desc",
          isRead: false,
        },
      });
      const unReadNotificationsCount = response.data.notifications.totalResults;
      dispatch(setNotificationsCount({ count: unReadNotificationsCount }));

      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Something went wrong");
    }
  }
);

const initialState = {
  notificationType: "all", // valid values all | read | unread
  unReadNotificationsCount: 0,
  hasNewNotifications: false,
  notifications: {
    notificationType: DATA_FETCHING_STATUSES.DATA_LOADING,
    data: [],
  },
};

export const notificationsSlice = createSlice({
  name: "notificationsSlice",
  initialState,
  reducers: {
    setNotificationType: (state, action) => {
      state.notificationType = action.payload.notificationType;
    },
    setNotificationsCount: (state, action) => {
      state.unReadNotificationsCount = action.payload.count;
    },
    setHasNewNotifications: (state, action) => {
      state.hasNewNotifications = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = {
        ...state.notifications,
        ...action.payload,
      };
    },
    setUpdateNotifications: (state, action) => {
      state.notifications = {
        ...state.notifications,
        data: {
          ...state.notifications.data,
          notifications: {
            ...state.notifications.data.notifications,
            results: [
              action.payload,
              ...state.notifications.data.notifications.results,
            ],
          },
        },
      };
    },
  },
});
export const {
  setNotificationType,
  setNotifications,
  setUpdateNotifications,
  setHasNewNotifications,
  setNotificationsCount,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

//Selector
export const useNotificationsSelector = () =>
  useSelector((state) => {
    return state.notificationsReducer;
  });

export const useHasNewNotificationSelector = () =>
  useSelector((state) => {
    return state.notificationsReducer.hasNewNotifications;
  });

export const useNotificationsCountSelector = () =>
  useSelector((state) => {
    return state.notificationsReducer.unReadNotificationsCount;
  });

export const useNotificationTypeSelector = () =>
  useSelector((state) => {
    return state.notificationsReducer.notificationType;
  });
