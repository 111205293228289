import { createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
  files: [],
};

const fileUploaderSlice = createSlice({
  name: "fileUploaderSlice",
  initialState,
  reducers: {
    setFiles: (state, { payload }) => {
      state.files = payload;
    },
    setResetFiles: (state) => {
      state.files = [];
    },
  },
});

export const { setResetFiles, setFiles } = fileUploaderSlice.actions;

export default fileUploaderSlice.reducer;

// Selector
export const useFilesSelector = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setResetFiles());
  }, []);

  return useSelector((state) => {
    return state.fileUploaderReducer.files;
  });
};
