import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
// Custom components
import Card from "components/card/Card";
import { Pagination } from "components/customs/pagination";
import { CustomNoDataFound } from "components/customs/noDataFound";

export function LeaderboardTable(props) {
  const { columnsData, tableData, totalPages, currentPage, setCurrentPage } =
    props;
  const tableInstance = useTable(
    {
      columns: columnsData,
      data: tableData,
      manualPagination: true, // Use manual pagination to handle API
      pageCount: totalPages,
      state: { pageIndex: currentPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
  } = tableInstance;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  function handleNext() {
    if (canNextPage) {
      setCurrentPage((prev) => prev + 1);
      nextPage();
    }
  }

  function handlePrevious() {
    if (!canPreviousPage) {
      setCurrentPage((prev) => prev - 1);
      previousPage();
    }
  }

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      // overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <TableContainer>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr 
                {...row.getRowProps()} 
                key={index}
                // bg={index < 3 ? "brand.150" : "inherit"}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        <Flex align="center">
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        </Flex>
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {totalPages === 0 ? (
          <CustomNoDataFound />
        ) : (
          <Pagination
            {...{
              currentPage,
              totalPages,
              handleNext,
              handlePrevious,
            }}
          />
        )}
      </TableContainer>
    </Card>
  );
}
