import { Box, Flex, Avatar } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { CustomNoDataFound } from "components/customs/noDataFound";
import avatar from "assets/img/avatars/avatar4.png";
import { BACKEND_BASE_URL } from "lib/constants";

function MessageItem({
  senderName,
  playerImage,
  senderImage,
  message,
  currentUser,
}) {
  const textColorBrand = useColorModeValue("brand.950", "white");

  return (
    <Flex
      justifyContent="space-between"
      alignItems="start"
      gap="8px"
      marginBottom="25px"
    >
      {!currentUser && (
        <Avatar
          src={`${BACKEND_BASE_URL}/${senderImage}`}
          h="50px"
          w="50px"
          border="2px solid #FFFFFF"
        />
      )}
      <Box
        position="relative"
        mr={currentUser ? "" : "auto"}
        ml={currentUser ? "auto" : ""}
        mb={"10px"}
        border="1px solid"
        borderColor={textColorBrand}
        bg={currentUser ? textColorBrand : "white"}
        color={currentUser ? "white" : textColorBrand}
        maxWidth="60%"
        minWidth="fit-content"
        borderRadius="3xl"
        p="3"
        fontWeight={700}
      >
        {message}
        {currentUser && (
          <Box
            whiteSpace="nowrap" 
            position="absolute"
            right="0px"
            bottom="-24px"
            border="1px solid"
            borderColor={textColorBrand}
            bg={"white"}
            color={textColorBrand}
            minWidth="80px"
            borderRadius="3xl"
            fontWeight={700}
            fontSize={12}
          >
            <Flex alignItems="center" paddingLeft="2">
              <Box margin="auto">{senderName ?? "Admin"}</Box>
              <Avatar
                background={ senderImage && "transparent"}
                marginLeft="auto"
                src={`${BACKEND_BASE_URL}/${senderImage}`}
                h="30px"
                w="30px"
                border="2px solid #FFFFFF"
              />
            </Flex>
          </Box>
        )}
      </Box>
    </Flex>
  );
}

export function Messages({ playerImage, messages, userID, selectedUserId }) {
  if (messages && messages?.length <= 0) {
    return <CustomNoDataFound value="No Message to Display." />;
  }
  return (
    <Box>
      {messages.map((msg) => {
        return (
          <MessageItem
            key={msg.id}
            message={msg.message}
            currentUser={msg?.senderRole !== "user"}
            senderImage={msg?.senderProfile}
            senderName={msg?.senderName}
            // playerImage={playerImage}
          />
        );
      })}
    </Box>
  );
}
