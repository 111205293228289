import React, { forwardRef } from "react";
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { Avatar } from "@chakra-ui/react";
import { BACKEND_BASE_URL } from "lib/constants";

export const UserList = forwardRef(
  ({ hasMore, allUsers, selectedUserId, setselectedUserId }, ref) => {
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "#D0BEA3";

    const dispatch = useDispatch();

    return (
      <>
        {allUsers.map((user) => {
          let profilePicture = null;
          if (user?.profile_picture) {
            profilePicture = `${BACKEND_BASE_URL}/${user?.profile_picture}`;
          }

          let name = user.name ?? user.username;
          if (isMobile) {
            name = name
              .slice(0, 2)
              .split("")
              .map((c) => c.toUpperCase())
              .join("");
          }

          let unreadChats = "";
          if (selectedUserId !== user.id && user?.unreadChats > 0) {
            unreadChats = user?.unreadChats > 9 ? "9+" : user?.unreadChats;
          }

          return (
            <Box
              key={user.id}
              onClick={() => setselectedUserId(user.id)}
              cursor="pointer"
              textAlign="center"
              color="white"
              bg={selectedUserId === user.id ? textColor : textColorSecondary}
              fontSize="xl"
              fontWeight="700"
              borderBottom="1px solid black"
              paddingY="8px"
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                padding="5px 15px"
              >
                <Avatar
                  src={profilePicture}
                  h="40px"
                  w="40px"
                  border="2px solid #FFFFFF"
                />
                <Box>{name}</Box>
                <Box
                  visibility={unreadChats ? "visible" : "hidden"}
                  borderRadius="50%"
                  background="navy.700"
                  height="25px"
                  minWidth="25px"
                  padding="0 6px"
                  fontWeight="bold"
                  fontSize="12px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  color="white"
                >
                  {unreadChats}
                </Box>
              </Flex>
            </Box>
          );
        })}
        {hasMore && (
          <Text
            ref={ref}
            textAlign="center"
            paddingY="8px"
            color="white"
            fontSize="2xl"
            fontWeight="700"
          >
            Loading...
          </Text>
        )}
      </>
    );
  }
);
