import { Select, Box, FormErrorMessage, FormControl } from "@chakra-ui/react";
import { CustomFormLabel } from "./Input";
import { Controller, useFormContext } from "react-hook-form";

export function CustomSelect({ label, name, placeholder, options, styles }) {
  const selectFont = styles?.selectFont ?? "";
  const selectWeight = styles?.selectWeight ?? "";
  const selectPadding = styles?.selectPadding ?? "";

  const {
    control,
    formState: { errors },
  } = useFormContext(); // Ensures this component can be used in a form context

  return (
    <FormControl isInvalid={errors[name]}>
      <Box mb="24px">
        {/* Label */}
        <CustomFormLabel label={label ?? ""} />

        {/* React Hook Form Controller */}
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                variant="auth"
                size="lg"
                fontSize="sm"
                borderColor="brand.950"
                _focus={{ borderColor: "brand.950" }}
                _hover={{ borderColor: "brand.950" }}
                value={value} // Controlled value from React Hook Form
                onChange={(e) => onChange(e.target.value)} // onChange handler from React Hook Form
                color="brand.950"
                placeholder={placeholder}
              >
                {options.map((option) => (
                  <option
                    key={option.value} // Add unique key
                    value={option.value}
                    color="brand.950"
                  >
                    {option.label}
                  </option>
                ))}
              </Select>
            );
          }}
        />

        {/* Error Message */}
        <FormErrorMessage>
          {errors?.[name] && errors?.[name]?.message}
        </FormErrorMessage>
      </Box>
    </FormControl>
  );
}
