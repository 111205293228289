import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { useDispatch } from "react-redux";
import { fetchUsersThunk } from "store/slices/auth";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import { useHistory } from "react-router-dom";

function Welcome() {
  // Chakra color mode
  const textColor = "rgba(116, 87, 43, 1)";
  const bgButtonGradient = "linear-gradient(180deg, #FFCE88 0%, #DD9122 100%)";
  const dispatch = useDispatch();
  const history = useHistory();

  async function handleSubmit() {
    const res = await dispatch(fetchUsersThunk({ page: 1, role: "players" }));
    if (res.payload === API_SUCCESS_RESPONSE_TAG) {
      history.push("/admin/players/?role=players");
    }
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100vh"
        alignItems="center"
        justifyContent="center"
        px={{ base: "25px", md: "0px" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="50px" ml={130}>
            Welcome!
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
        >
          <Button
            onClick={handleSubmit}
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            // as={NavLink}
            // to="/admin/players"
            bg={bgButtonGradient}
            _hover={{ opacity: 0.8 }}
            size="lg"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text
              fontFamily={"'DM Sans', sans-serif"}
              fontWeight="700"
              fontSize="28px"
              lineHeight="28px"
              textAlign="center"
            >
              Get Started
            </Text>
          </Button>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Welcome;
