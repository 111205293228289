import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Grid,
  GridItem,
  Box,
  Text,
  useColorModeValue,
  Image,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import goldenAcorn from "assets/img/golden-acorns.svg";
import simpleAcorn from "assets/img/simple_acorn.svg";
import giftCard from "assets/img/Gift_Card.png";
import { PrimayButton } from "components/customs/buttons";
import { schema } from "./schema";
import { FormProvider, useForm } from "react-hook-form";
import Card from "components/card/Card";
import { CustomFormInput } from "components/customs/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatRewardType } from "lib/helpers";
import { CustomSelect } from "components/customs/select";
import { CustomFormDatePicker } from "components/customs/datePicker";
import { useEffect, useState } from "react";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import { createRewardsThunk } from "store/slices/rewards";
import { formatPayloadDate } from "lib/helpers";
import { useRewardsSelector } from "store/slices/rewards";
import { useSearchParams } from "./reward";
import { updateRewardsThunk } from "store/slices/rewards";
import { checkRewardType } from "lib/helpers";
import { REWARD_OPTIONS } from "lib/helpers";
import { RANK_TYPES_OPTIONS } from "lib/helpers";
import { isBefore } from "date-fns";
import { REWARD_TYPES_REVERSE_MAP } from "lib/constants";

export function RewardModal({
  isOpen,
  onClose,
  selectedDate,
  handleSelectDate,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { searchParams } = useSearchParams();
  const id = searchParams.get("id");
  const rewardsState = useRewardsSelector();
  const rewards = rewardsState?.rewards;
  const reward = rewards?.data?.results?.find((r) => r.id === id);

  const defaultValues = {
    rewardValue: reward?.rewardValue ?? 1,
    rewardType: reward?.rewardType ?? REWARD_OPTIONS[0].value,
    cardCode: reward?.cardCode ?? "",
    rank: reward?.rank ?? 1,
    rewardDate:
      reward?.rewardDate?.split("T")?.[0] ??
      formatPayloadDate(
        isBefore(selectedDate, new Date()) ? new Date() : selectedDate
      ),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields },
  } = methods;

  useEffect(() => {
    return () => {
      history.push("/admin/rewards");
    };
  }, []);

  const newRewardDate = watch("rewardDate");
  useEffect(() => {
    handleSelectDate(newRewardDate);
  }, [newRewardDate]);

  const onSubmit = async (data) => {
    let payload = data;
    if (!is_gift_card) {
      const { cardImage, cardCode, ...restPayload } = payload;
      payload = restPayload;
    }

    if (id) {
      payload = Object.keys(payload).reduce((acc, current) => {
        if (dirtyFields[current]) {
          acc[current] = payload[current];
        }
        return acc;
      }, {});

      payload.id = id;

      const res = await dispatch(
        updateRewardsThunk({ ...payload, selectedRewardDate: selectedDate })
      );
      if (res.payload === API_SUCCESS_RESPONSE_TAG) {
        onClose();
      }
      return;
    }

    const res = await dispatch(
      createRewardsThunk({ ...payload })
    );
    if (res.payload === API_SUCCESS_RESPONSE_TAG) {
      onClose();
    }
  };


  const rewardType = watch("rewardType");
  const { is_gift_card, is_golden_acorns, is_simple_acorns } =
    checkRewardType(rewardType);

  let previewImage = giftCard;
  let previewImageWidth = "350px";
  let previewImageHeigth = "230px";


  if (is_gift_card) {
    previewImage = giftCard;
    previewImageWidth = "330px";
    previewImageHeigth = "230px";
  }

  if (is_golden_acorns) {
    previewImage = goldenAcorn;
    previewImageWidth = "300px";
    previewImageHeigth = "380px";
  }

  if (is_simple_acorns) {
    previewImage = simpleAcorn;
    previewImageWidth = "300px";
    previewImageHeigth = "380px";
  }
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalHeader>
                <Text
                  color={textColor}
                  fontSize="40px"
                  fontWeight="700"
                  lineHeight="100%"
                >
                  {formatRewardType(
                    REWARD_TYPES_REVERSE_MAP[watch("rewardType")]
                  )}
                </Text>
              </ModalHeader>
              <ModalCloseButton
                borderRadius="50%"
                size="lg" // Custom size
                bg="brand.950"
                color="white"
                fontWeight="bold"
                _hover={{ bg: "brand.950", color: "white" }} //
              />

              <ModalBody>
                <Box pt="5">
                  <Card mb={{ base: "0px", lg: "20px" }} align="center">
                    <Grid
                      templateColumns={{
                        base: "repeat(1, 1fr)",
                        lg: "repeat(2, 1fr)",
                      }}
                      gap={{ base: 3, lg: 10 }}
                    >
                      <GridItem order={2}>
                        <Box
                          p="5"
                          bg="secondaryGray.400"
                          height={is_gift_card ? "550px" : "auto"}
                          borderRadius="lg"
                          overflow="hidden"
                        >
                          <Box
                            p="5"
                            mb={is_gift_card ? "10" : "0px"}
                            bg="secondaryGray.50"
                            height="85%"
                            borderRadius="lg"
                            overflow="hidden"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Box>
                              <Box
                                width={previewImageWidth}
                                height={previewImageHeigth}
                                borderRadius="lg"
                                overflow="hidden"
                              >
                                <Image
                                  objectFit="cover"
                                  width="100%"
                                  height="100%"
                                  src={previewImage}
                                />
                              </Box>
                              <FormControl isInvalid={errors?.cardImage}>
                                <FormErrorMessage>
                                  {errors?.cardImage &&
                                    errors?.cardImage?.message}
                                </FormErrorMessage>
                              </FormControl>
                            </Box>
                          </Box>
                        </Box>
                      </GridItem>
                      <GridItem order={1}>
                        <CustomSelect
                          styles={{
                            selectFont: 40,
                            selectWeight: "bold",
                            selectPadding: 5,
                          }}
                          options={REWARD_OPTIONS}
                          label="Reward Type"
                          name="rewardType"
                        />
                        {is_gift_card && (
                          <CustomFormInput
                            {...{
                              placeholder: "Code",
                              type: "text",
                              name: "cardCode",
                              label: "Gift Card# (code)",
                              isRequired: true,
                            }}
                          />
                        )}
                        <CustomFormInput
                          {...{
                            placeholder: is_gift_card ? "Amount" : "Quantity",
                            type: "text",
                            name: "rewardValue",
                            label: is_gift_card
                              ? "Gift Card (amount)"
                              : "Quantity(acorns)",
                            isRequired: true,
                          }}
                        />
                        <CustomSelect
                          options={RANK_TYPES_OPTIONS}
                          label="Rank"
                          name="rank"
                        />
                        <CustomFormDatePicker
                          label="Reward Date"
                          name="rewardDate"
                        />
                        <PrimayButton
                          key="submit"
                          label={id ? "Update" : "Create"}
                          isLoading={isSubmitting}
                        />
                      </GridItem>
                    </Grid>
                  </Card>
                </Box>
              </ModalBody>
            </form>
          </FormProvider>
        </ModalContent>
      </Modal>
    </>
  );
}
