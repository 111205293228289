// Chakra imports
import {
  background,
  Box,
  Flex,
  HStack,
  SimpleGrid,
  Spinner,
  Tag,
  TagLabel,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import { rewardsTableColumns } from "./rewardsColumns";
import { DATA_FETCHING_STATUSES } from "lib/constants";
import { RewardsTable } from "./rewardTable";
import { useColors } from "components/customs/colors";
import { useHistory, useLocation } from "react-router-dom";
import { deleteUserThunk } from "store/slices/auth";
import { useLoadingSelector } from "store/slices/loading";
import { formatDate } from "lib/helpers";
import { fetchRewardsThunk, useRewardsSelector } from "store/slices/rewards";
import { formatRewardType } from "lib/helpers";
import { CustomClaimTag } from "components/customs/tags";
import { deleteRewardsThunk } from "store/slices/rewards";
import { isAfter, isBefore } from "date-fns";
import { ConfirmationModal } from "components/customs/confirmationModal";
import {
  fetchGameEntryFeeThunk,
  updateGameEntryFeeThunk,
  useGameSettingsSelector,
} from "store/slices/auth";

export default function GlobalSettings() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenConfirmModal,
    onOpen: onOpenConfirmModal,
    onClose: onCloseConfirmModal,
  } = useDisclosure();

  const { textColor } = useColors();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

 

  const loading = useLoadingSelector();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState("");
  const [selectedDate, setSeletedDate] = useState(new Date());
  const settingsState = useGameSettingsSelector();
  const { totalPages, totalResults } = settingsState.data || { totalPages: 0, totalResults: 0 };


  useEffect(() => {
    const limit = 10; // Define the limit for pagination

    dispatch(fetchGameEntryFeeThunk( {limit, page: currentPage }))

  }, [isOpen,onOpen,onClose,currentPage]);

  // Extract the required values from the settings state

  function handleSelectDate(selectedDate) {
    setSeletedDate(selectedDate);
  }

  function handleEdit({ id }) {
    onOpen();
    history.push(`${location.pathname}/edit?id=${id}`);
  }

  function handleDelete({ id }) {
    dispatch(deleteRewardsThunk({ id, selectedDate }));
  }

  function handleAction({ action, id }) {
    if (loading) return;

    if (action === "Edit") {
      handleEdit({ id });
      return;
    }

    if (action === "Delete") {
      onOpenConfirmModal();
      setSelectedRow(id);
      return;
    }
  }
  const settingsArray = settingsState.data.settings || [];
  const tableRows = useMemo(
    () =>
      settingsArray.map((setting) => ({
        date: setting.validOn ? format(new Date(setting.validOn), "MMMM dd, yyyy") : "N/A",
        entry_fee: setting.entryFee ?? "N/A",
        golden_acorns_limit: setting.goldenAcornsLimit ?? "N/A",
        // start_date: setting.startDate ?? "N/A",
        // end_date: setting.endDate ?? "N/A",
      })),
    [settingsArray]
  );

  let component = (
    <Flex justifyContent="center" mt="28">
      <Spinner size="xl" color="brand.950" />
    </Flex>
  );

  // if (rewards?.status === DATA_FETCHING_STATUSES.DATA_LOADED) {
    component = (
      <RewardsTable
        columnsData={rewardsTableColumns}
        tableData={tableRows}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        selectedDate={selectedDate}
        handleSelectDate={handleSelectDate}
        {...{
          isOpen,
          onOpen,
          onClose,
      
        }}
      />
    );
  // }
  return (
    <>
      {isOpenConfirmModal && (
        <ConfirmationModal
          {...{
            headerText: "Delete Reward Confirmation",
            isOpen: isOpenConfirmModal,
            onOpen: onOpenConfirmModal,
            onClose: () => {
              setSelectedRow("");
              onCloseConfirmModal();
            },
            handleAction: () => handleDelete({ id: selectedRow }),
          }}
        />
      )}
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid mb="20px" spacing={{ base: "20px", xl: "20px" }}>
          {component}
        </SimpleGrid>
      </Box>
    </>
  );
}
