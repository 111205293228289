import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Grid,
  GridItem,
  Image,
  FormErrorMessage,
  FormControl,
  Box,
  Flex,
  Heading,
  Text,
  useColorModeValue,
  FormLabel,
  Spinner,
} from "@chakra-ui/react";

import { PrimayButton } from "components/customs/buttons";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import Card from "components/card/Card";
import { CustomFormInput } from "components/customs/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatRewardType } from "lib/helpers";

import { useEffect, useState } from "react";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import { format } from 'date-fns';

import { REWARD_TYPES_REVERSE_MAP } from "lib/constants";
import { HSeparator } from "components/separator/Separator";
import { useAuth } from "contexts/AuthContext";
import DatePicker from "react-datepicker";
import { useAuthSelector } from "store/slices/auth";
import { useProtectedAdminRoutes } from "lib/hooks";
import {
  fetchGameEntryFeeThunk,
  updateGameEntryFeeThunk,
  useGameSettingsSelector,
} from "store/slices/auth";
import { DEFAULT_ENTRY_FEE } from "store/slices/auth";
import { DATA_FETCHING_STATUSES } from "lib/constants";
const schema = yup.object().shape({
  entryFee: yup
    .number()
    .typeError("Only numbers are allowed for game entry fee.")
    .integer("Decimal numers are not allowed.")
    .min(1, "Game entry fee cannot be less than 1.")
    .max(30, "Game entry fee cannot be greater than 30.")
    .required("Game entry fee is required."),
    goldenAcornsLimit: yup
    .number()
    .typeError("Only numbers are allowed for golden acorn use limit.")
    .integer("Decimal numbers are not allowed.")
    .min(0, "Golden acorn use limit cannot be less than 0.")
    .required("Golden acorn use limit is required."),
    fromDate: yup
    .date()
    .required("From date is required.")
    .typeError("Invalid date format."),
  toDate: yup
    .date()
    .required("To date is required.")
    .typeError("Invalid date format.")
    .min(yup.ref('fromDate'), "To date cannot be earlier than from date."),
});

export function RewardModal({ isOpen, onClose, selectedDate, handleSelectDate }) {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleFromDateChange = (date) => {
    if (date) {
      const formattedDate = format(date, 'yyyy-MM-dd');
      setFromDate(formattedDate);
      setValue('fromDate', formattedDate);
      clearErrors('fromDate');
    }
  };

  const handleToDateChange = (date) => {
    if (date) {
      const formattedDate = format(date, 'yyyy-MM-dd');
      setToDate(formattedDate);
      setValue('toDate', formattedDate);
      clearErrors('toDate');
    }
  };
// .

  const methods = useForm({
    defaultValues: {
      entryFee: null,
      goldenAcornsLimit: null,
      fromDate: null,
      toDate: null,
    },
    resolver: yupResolver(schema),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    setValue,
    clearErrors
  } = methods;

  const onSubmit = async (data) => {
  
    const res = await dispatch(
      updateGameEntryFeeThunk({
        entryFee: data.entryFee,
        goldenAcornsLimit: data.goldenAcornsLimit,
        startDate:  format(data.fromDate, 'yyyy-MM-dd'),
        endDate: format(data.toDate, 'yyyy-MM-dd'),
      })
    );
    if (res.payload === API_SUCCESS_RESPONSE_TAG) {
      // Handle success
      onClose()
    }
  };
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "#D0BEA3";
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
          
            <ModalCloseButton borderRadius="50%" size="lg" bg="brand.950" color="white" fontWeight="bold" _hover={{ bg: "brand.950", color: "white" }} />
            <ModalBody>
              <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                <Card mb={{ base: "0px", lg: "20px" }} sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Heading textAlign="center" color={textColor} fontSize="36px" mb="10px">
                      Game Global Settings
                    </Heading>
                    <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
                      Set limits and rules for game economy and participation
                    </Text>
                  </Box>
                  <Flex zIndex="2" direction="column" w={{ base: "100%", md: "420px" }} maxW="100%" background="transparent" borderRadius="15px" mx={{ base: "auto", lg: "unset" }} me="auto" mb={{ base: "20px", md: "auto" }}>
                    <Flex align="center" mb="25px">
                      <HSeparator />
                    </Flex>
                    <fieldset disabled={isSubmitting}>
                      <FormControl isInvalid={errors.entryFee}>
                        <Box mb="24px">
                          <CustomFormInput
                            placeholder="Enter the game entry fee"
                            type="text"
                            name="entryFee"
                            label="Game Entry Fee"
                            isRequired={true}
                          />
                        </Box>
                      </FormControl>
                      <FormControl isInvalid={errors.goldenAcornsLimit}>
                        <Box mb="24px">
                          <CustomFormInput
                            placeholder="Golden Acorn Use Limit"
                            type="text"
                            name="goldenAcornsLimit"
                            label="Golden Acorn Use Limit"
                            isRequired={true}
                          />
                        </Box>
                      </FormControl>
                      <Flex mb="24px" justifyContent="space-between">
                        <FormControl mr="10px" isInvalid={errors.fromDate}>
                          <FormLabel>From Date</FormLabel>
                          <DatePicker
                            selected={fromDate}
                            onChange={handleFromDateChange}
                            minDate={new Date()}
                            placeholderText="Select a start date"
                          />
                          <FormErrorMessage>
                            {errors.fromDate && errors.fromDate.message}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl ml="10px" isInvalid={errors.toDate}>
                          <FormLabel>To Date</FormLabel>
                          <DatePicker
                            selected={toDate}
                            onChange={handleToDateChange}
                            minDate={fromDate || new Date()}
                            placeholderText="Select an end date"
                          />
                          <FormErrorMessage>
                            {errors.toDate && errors.toDate.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                      <PrimayButton
                        disabled={!isDirty}
                        label="Add"
                        key="submit"
                        // type="submit"
                        isLoading={isSubmitting}
                        w="100%"
                      />
                    </fieldset>
                  </Flex>
                </Card>
              </Box>
            </ModalBody>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
