import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Flex, Text, Icon, VStack, HStack, Box } from "@chakra-ui/react";
import { FiUpload, FiFileText } from "react-icons/fi";
import { toast } from "react-toastify";
import { PrimayButton } from "components/customs/buttons";
import Papa from "papaparse";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import { bulkCreateRewardsThunk } from "store/slices/rewards";
import { useDispatch } from "react-redux";
import { IoMdCloseCircle } from "react-icons/io";
import { useLoadingSelector } from "store/slices/loading";
import { format } from "date-fns";

export const FileUploadDropzone = ({
  label,
  description,
  maxFileSize,
  acceptedFiles,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const [uploadedFile, setUploadedFile] = useState(null);
  const loading = useLoadingSelector();

  // Handle dropped files
  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      // Handle valid files
      if (acceptedFiles.length > 1) {
        toast.error("Only one file is allowed.");
      }

      if (acceptedFiles.length > 0 && acceptedFiles.length < 2) {
        setUploadedFile(acceptedFiles[0]);
      }

      // Handle rejections (invalid files)
      fileRejections.forEach((rejection) => {
        rejection.errors.forEach((error) => {
          let errorMessage = "Invalid file.";
          if (error.code === "file-too-large") {
            errorMessage = "File is too large. Max file size is 5MB.";
          } else if (error.code === "file-invalid-type") {
            errorMessage = "Invalid file type. Only CSV files are allowed.";
            // "Invalid file type. Only CSV or Excel files are allowed.";
          }
          toast.error(errorMessage);
        });
      });
    },
    [toast]
  );

  // React Dropzone hook
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFiles,
    maxSize: maxFileSize,
  });

  const handleCreateBulkReward = () => {
    Papa.parse(uploadedFile, {
      header: true, // Parse CSV into JSON objects
      dynamicTyping: true, // Auto-detect data types
      skipEmptyLines: "greedy",
      transform: (value, field) => {
        // keep the date format consistent - excel and google sheet handles date format differently
        if(field === 'Reward Date') {
          return format(new Date(value), "yyyy-MM-dd")
        }

        return value
      },
      complete: (results) => {
        // Callback after parsing
        dispatch(bulkCreateRewardsThunk(results.data)).then((res) => {
          if (res.payload === API_SUCCESS_RESPONSE_TAG) {
            handleClose();
          }
        });
      },
      error: (error) => {
        toast.error(`Error parsing CSV: ${error.message}`);
        console.error("Error parsing CSV:", error.message);
      },
    });
  };

  if (uploadedFile) {
    return (
      <Flex
        flex="1"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <VStack spacing={4}>
          <Box position="relative">
            <Icon as={FiFileText} w={12} h={12} color="navy.700" />
            <Box position="absolute" zIndex={99} top={-2} right={"-6px"}>
              <Icon
                w={6}
                h={6}
                as={IoMdCloseCircle}
                color="red.600"
                onClick={() => setUploadedFile(null)}
                cursor="pointer"
              />
            </Box>
          </Box>
          <Text>{uploadedFile.name}</Text>
          <HStack>
            <PrimayButton
              isLoading={loading}
              onClick={handleCreateBulkReward}
              width="100%"
              key="create"
              label="Create Rewards"
            />
          </HStack>
        </VStack>
      </Flex>
    );
  }

  return (
    <Flex
      flex="1"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      {...getRootProps()}
      border="2px dashed"
      // borderColor="navy.700"
      borderRadius="md"
      p={8}
      textAlign="center"
      cursor="pointer"
      color="navy.600"
      _hover={{ borderColor: "navy.700", color: "navy.700" }}
    >
      <input {...getInputProps()} />
      <VStack spacing={4}>
        <Box>
          <Icon as={FiUpload} boxSize={8} />
          <Text fontSize="lg">{label}</Text>
          <Text fontSize="xs">{`(${description})`}</Text>
        </Box>
        <Text fontSize="lg" margin={0}>
          or
        </Text>
        <PrimayButton
          width="100%"
          key="upload-button"
          label="Click to Upload"
        />
      </VStack>
    </Flex>
  );
};
