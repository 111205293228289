// Chakra imports
import { Box, Flex, SimpleGrid, Spinner } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { notificationsColumns } from "./notificationsColumns";
import { DATA_FETCHING_STATUSES } from "lib/constants";
import { NotificationsTable } from "./notificationsTable";
import { useColors } from "components/customs/colors";
import { useHistory, useLocation } from "react-router-dom";
import { useLoadingSelector } from "store/slices/loading";
import { formatDate } from "lib/helpers";
import { useSearchParams } from "../rewards/reward";
import { useAuthSelector } from "store/slices/auth";
import {
  fetchNotificationsThunk,
  useNotificationsSelector,
  readAllNotificationsThunk, 
  useNotificationTypeSelector, 
  setNotificationType ,
} from "store/slices/notifications";


export function Notifications() {
  const { textColor } = useColors();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useAuthSelector();
  const notificationsState = useNotificationsSelector();
  const { searchParams } = useSearchParams();
  const userId = searchParams.get("id");
  const notifications =
    notificationsState?.notifications?.data?.notifications?.results ?? [];
  const totalPages =
    notificationsState?.notifications?.data?.notifications?.totalPages ?? 0;
  const requestStatus = notificationsState?.notifications?.status;
  const loading = useLoadingSelector();
  const [currentPage, setCurrentPage] = useState(1);
  const notificationType = useNotificationTypeSelector();

  async function handleMarkAllAsRead() {
    await dispatch(readAllNotificationsThunk({ page: 1, notificationType }));
    setCurrentPage(1);
    // setNotificationType("all")
  }

  function handleNotificationType({ notificationType }) {
    dispatch(setNotificationType({ notificationType }));
  }

  useEffect(() => {
    dispatch(fetchNotificationsThunk({ notificationType, page: currentPage }));
  }, [currentPage, notificationType]);

  const tableRows = useMemo(
    () =>
      notifications?.map((notification) => ({
        id: notification.id,
        readBy: notification?.readBy ?? [],
        message: notification.message,
        date: notification?.notificationDate
          ? formatDate(new Date(notification?.notificationDate))
          : "N/A",
      })),
    [notificationsState, loading]
  );

  let component = (
    <Flex justifyContent="center" mt="28">
      <Spinner size="xl" color="brand.950" />
    </Flex>
  );

  if (requestStatus === DATA_FETCHING_STATUSES.DATA_LOADED) {
    component = (
      <NotificationsTable
        loading={loading}
        handleMarkAllAsRead={handleMarkAllAsRead}
        notificationType={notificationType}
        handleNotificationType={handleNotificationType}
        columnsData={notificationsColumns}
        tableData={tableRows}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mb="20px" spacing={{ base: "20px", xl: "20px" }}>
        {component}
      </SimpleGrid>
    </Box>
  );
}
