import { Flex, Input, useMediaQuery } from "@chakra-ui/react";
import { PrimayButton } from "components/customs/buttons";
import { useSocketContext } from "contexts/SocketContext";
import { SOCKET_EVENTS } from "lib/constants";
import { toast } from "react-toastify";
import { useState, useRef, useEffect } from "react";
import { BACKEND_BASE_URL } from "lib/constants";

export function ChatForm({
  currentAdmin,
  senderId,
  receiverId,
  updateMessages,
}) {
  const { socket } = useSocketContext();
  const [isMobile] = useMediaQuery("(max-width: 425px)");
  const [inputMsg, setInputMsg] = useState("");
  const focusRef = useRef(null);

  const handleChange = (event) => {
    setInputMsg(event.target.value);
  };
  // console.log("Plan For Today:=>>>", socket);
  // const handleResponse = ({ response, message }) => {
  //   updateMessages({ message });
  //   // console.log("root level response=>", response);

  //   if (response === "Message sent successfully") {
  //     console.log("handleResponse=>>", { response, message });
  //     updateMessages({ message });
  //   } else {
  //     // console.log("response=>", response);
  //     toast.error("Message sending failed");
  //   }
  // };

  useEffect(() => {
    if (focusRef.current && !!receiverId) {
      focusRef.current?.focus();
    }
  }, [receiverId]);

  const sendMessage = ({ message }) => {
    socket?.emit(
      SOCKET_EVENTS.SEND_MESSAGE,
      {
        message,
        receiverId,
      }
      // ({ response }) => handleResponse({ response, message })
    );

    updateMessages({
      message: {
        message,
        receiverId,
        senderId,
        senderName: currentAdmin?.username ?? "Admin",
        senderProfile: `${currentAdmin.profile_picture}`,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const message = inputMsg.trim();
    if (!message || !receiverId) return;
    sendMessage({ message });
    setInputMsg("");
    // const formdata = new FormData(e.currentTarget);
    // const message = formdata.get("message");
    // e.currentTarget.reset();
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        position: "absolute",
        bottom: 0,
        background: "white",
        width: isMobile ? "auto" : "100%",
      }}
    >
      <Flex gap={2} p={{ sm: "0px 10px 25px 0px", md: "25px 10px" }}>
        <Input
          ref={focusRef}
          borderRadius="3xl"
          name="message"
          value={inputMsg}
          onChange={handleChange}
        />
        <PrimayButton
          label="Send"
          disabled={!inputMsg || inputMsg?.trim().length < 1 || !receiverId}
        />
      </Flex>
    </form>
  );
}
