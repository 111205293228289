import React from "react";
import { Box, Flex, Heading, Text, useColorModeValue } from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import { useAuth } from "contexts/AuthContext";
import { useAuthSelector } from "store/slices/auth";
import { resetPasswordThunk } from "store/slices/auth";
import { useDispatch } from "react-redux";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Card from "components/card/Card";
import { PrimayButton } from "components/customs/buttons";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { CustomFormInput } from "components/customs/Input";
import { useSearchParams } from "../rewards/reward";

const schema = yup.object().shape({
  // token: yup.string().trim().required("Verification code is required."),
  // email: yup
  //   .string()
  //   .trim()
  //   .email("Please enter a valid email.")
  //   .required("Email is required."),
  password: yup
    .string()
    .trim()
    .min(8, "Password must contain at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character"
    )
    .required("Password is a required field"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

export function ResetPassword() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "#D0BEA3";
  const inputColor = "#75582B";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const bgButtonGradient = "linear-gradient(180deg, #FFCE88 0%, #DD9122 100%)";
  const { login } = useAuth();
  const [show, setShow] = React.useState(false);
  const { searchParams } = useSearchParams();
  const token = searchParams.get("token");

  const dispatch = useDispatch();
  const handleClick = () => setShow(!show);
  const auth = useAuthSelector();
  const history = useHistory();
  const methods = useForm({
    defaultValues: {
      // email: "",
      // token: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty, dirtyFields },
  } = methods;

  // function handleReset() {
  //   reset({
  //     password: "",
  //     confirmPassword: "",
  //     email: user?.email ?? "",
  //     rank: user?.rank ?? "N/A",
  //     highest_score: user?.highest_score ?? "",
  //     username: user?.username ?? "",
  //     acorns: user?.acorns ?? 0,
  //     golden_acorns: user?.golden_acorns ?? 0,
  //   });
  // }

  const onSubmit = async (data) => {
    const res = await dispatch(
      resetPasswordThunk({
        token,
        password: data?.password,
        // email: data?.email,
      })
    );
    if (res.payload === API_SUCCESS_RESPONSE_TAG) {
      history.push("/auth/sign-in");
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading
            textAlign="center"
            color={textColor}
            fontSize="36px"
            mb="10px"
          >
            Reset Password
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter Enter your new password to reset.
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Flex align="center" mb="25px">
            <HSeparator />
          </Flex>
          <FormProvider {...methods}>
            <fieldset disabled={isSubmitting}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* <CustomFormInput
                  {...{
                    name: "email",
                    placeholder: "Your email",
                    type: "text",
                    label: "Email",
                    isRequired: true,
                  }}
                /> */}
                <CustomFormInput
                  {...{
                    placeholder: "Enter your new password",
                    type: "password",
                    name: "password",
                    label: "New Password",
                    isRequired: true,
                  }}
                />
                <CustomFormInput
                  {...{
                    placeholder: "Confirm your new password",
                    type: "password",
                    name: "confirmPassword",
                    label: "Confirm Password",
                    isRequired: true,
                  }}
                />
                {/* <CustomFormInput
                  {...{
                    placeholder: "Enter the verification code",
                    type: "text",
                    name: "token",
                    label: "Verification Code",
                    isRequired: true,
                  }}
                /> */}
                <PrimayButton
                  width="100%"
                  label="Reset Password"
                  isLoading={isSubmitting}
                />
              </form>
            </fieldset>
          </FormProvider>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}
