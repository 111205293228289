export const rewardsTableColumns = [
  {
    Header: "Rank",
    accessor: "rank_index",
  },
  {
    Header: "Reward",
    accessor: "title",
  },
  {
    Header: "Amount",
    accessor: "reward_value",
  },
  // {
  //   Header: "Claim Status",
  //   accessor: "claim_status",
  // },
  // {
  //   Header: "Reward Awarded Date",
  //   accessor: "reward_awarded_date",
  // },
  {
    Header: "Winner Name",
    accessor: "winner_name",
  },
  {
    Header: "Winner ID",
    accessor: "winner",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
