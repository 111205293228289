import { Heading } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";

export function CustomHeading({ text = "", textAlign = "left" }) {
  const textColor = useColorModeValue("navy.700", "white");

  return (
    <Heading textAlign={textAlign} color={textColor} fontSize="32px" mb="10px">
      {text}
    </Heading>
  );
}
