export const leaderboardColumns = [
  {
    Header: "Rank",
    accessor: "rank",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Player ID",
    accessor: "player_id",
  },
  {
    Header: "Score",
    accessor: "score",
  },
  {
    Header: "Game Time",
    accessor: "gameTime",
  },
  // {
  //   Header: "Joined Date",
  //   accessor: "joined_date",
  // },
];
