export const API_SUCCESS_RESPONSE_TAG = "API_SUCCESS_RESPONSE_TAG";

export const DATA_FETCHING_STATUSES = {
  DATA_LOADING: "DATA_LOADING",
  DATA_LOADED: "DATA_LOADED",
};

export const LOCAL_STROAGE_KEYS = {
  USER: "USER",
  ACCESS_TOKEN: "ACCESS_TOKEN",
  REFRESH_ACCESS_TOKEN: "REFRESH_ACCESS_TOKEN",
};

export const LOCAL_STROAGE_USER_GET_KEY = `persist:${LOCAL_STROAGE_KEYS.USER}`;

export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const url = API_BASE_URL.split("/");
url.pop();
export const BACKEND_BASE_URL = url.join("/");
export const ENDPOINTS = {
  LOGIN: "/auth/admin/login",
  LOGOUT: "/auth/admin/logout",
  SIGN_UP: "/auth/register-admin",
  REFRESH_TOKEN: "/auth/refresh-tokens",
  FORGOT_PASSWORD: "/auth/forgot-password",
  RESET_PASSWORD: "/auth/reset-password",
  VERIFY_EMAIL: "/auth/verify-email",
  SEND_EMAIL_VERIFICATION: "/auth/send-verification-email",
  USERS: "/users",
  UPDATE_PROFILE_PICTURE: "/users/upload-profile-picture",
  REWARDS: "/rewards",
  LEADERBOARD: "/leaderboard",
  SEND_INVITATION: "/auth/send-admin-invite",
  CHATS: "chats",
  PLAYER_GAME_HISTORY: "/user-stats",
  GAME_ENTRY_FEE: "/admin-fee",
  NOTIFICATIONS: "/admin-notifications",
};

export const REWARD_TYPES_MAP = {
  // LOGIN_ACORNS: "LOGIN_ACORNS",
  // LOGIN_GOLDEN_ACORNS: "LOGIN_GOLDEN_ACORNS",
  GIFT_CARD: "LEADERBOARD_GIFT_CARD",
  ACORNS: "LEADERBOARD_ACORNS",
  GOLDEN_ACORNS: "LEADERBOARD_GOLDEN_ACORNS",
};

export const REWARD_TYPES_REVERSE_MAP = Object.entries(REWARD_TYPES_MAP).reduce(
  (acc, current) => {
    return {
      ...acc,
      [current[1]]: current[0],
    };
  },
  {}
);

export const REWARD_TYPES = Object.values(REWARD_TYPES_MAP);

export const SOCKET_EVENTS = {
  SEND_MESSAGE: "sendMessageToUser",
  RECEIVE_MESSAGE: "receiveMessage",
  RECEIVE_NOTIFICATIONS: "sendNotifications",
  MARK_CHAT_AS_READ: "markChatsAsReadFromAdmin",
};

export const ROLES = {
  SUPER_ADMIN: "superAdmin",
  ADMIN: "admin",
  PLAYER: "player",
};
// export const URLS = Object.keys(ENDPOINTS).reduce((acc, current) => {
//   acc[current] = API_BASE_URL + ENDPOINTS[current];
//   return acc;
// }, {});
