import { useFormContext } from "react-hook-form";
import {
  Box,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Text,
  useColorModeValue,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useState } from "react";

export function CustomFormInput({
  placeholder = "",
  type = "text",
  name = "",
  label,
  isRequired = false,
}) {
  const {
    formState: { errors },
  } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const inputColor = "#75582B";

  return (
    <FormControl isInvalid={errors[name]}>
      <CustomFormLabel {...{ label, isRequired }} />
      <Box mb="24px">
        <InputGroup size="md">
          <CustomInput
            {...{
              name,
              type: showPassword ? "text" : type,
              placeholder,
            }}
          />
          {type === "password" && (
            <InputRightElement display="flex" alignItems="center" mt="4px">
              <Icon
                color={inputColor}
                _hover={{ cursor: "pointer" }}
                as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                onClick={() => setShowPassword((prev) => !prev)}
              />
            </InputRightElement>
          )}
        </InputGroup>
        <FormErrorMessage>
          {errors?.[name] && errors?.[name]?.message}
        </FormErrorMessage>
      </Box>
    </FormControl>
  );
}

export function CustomInput({ placeholder = "", type = "text", name = "" }) {
  const { register } = useFormContext();
  return (
    <Input
      {...register(name)}
      id={name}
      variant="auth"
      fontSize="sm"
      ms={{ base: "0px", md: "0px" }}
      type={type}
      placeholder={placeholder}
      fontWeight="500"
      size="lg"
      _placeholder={{ color: "grey.500" }}
      borderColor="brand.950"
      color="grey.700"
    />
  );
}

export function CustomFormLabel({ label, isRequired = false }) {
  const textColor = useColorModeValue("brand.950", "white");
  const brandStars = useColorModeValue("red.400", "brand.400");
  return (
    <FormLabel
      display="flex"
      ms="4px"
      fontSize="sm"
      fontWeight="500"
      color={textColor}
      mb="8px"
    >
      {label}
      {isRequired && <Text color={brandStars}>*</Text>}
    </FormLabel>
  );
}

export function SearchBar({ styles, search, handleSearch }) {
  return (
    <Input
      value={search}
      onChange={(e) => {
        handleSearch(e.target.value);
      }}
      variant="auth"
      fontSize="sm"
      ms={{ base: "0px", md: "0px" }}
      type="text"
      placeholder="Search..."
      fontWeight="500"
      size="lg"
      _placeholder={{ color: "grey.500" }}
      borderColor="brand.950"
      color="grey.700"
      {...styles}
    />
  );
}
