import { Tag, TagLabel } from "@chakra-ui/react";

export function CustomClaimTag({ label }) {
  return (
    <Tag
      size="md"
      key="md"
      borderRadius="full"
      variant="solid"
      colorScheme={label === "claimed" ? "whatsapp" : "yellow"}
    >
      <TagLabel>{label}</TagLabel>
    </Tag>
  );
}
