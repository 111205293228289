import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Box,
  Text,
  useColorModeValue,
  Flex,
  ModalCloseButton,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { PrimayButton } from "components/customs/buttons";

export function ConfirmationModal({
  isOpen,
  onClose,
  handleAction,
  headerText,
}) {
  const headingColor = useColorModeValue("secondaryGray.900", "white");
  const modalBgColor = useColorModeValue("brand.1000", "white");

  function handleConfirm() {
    handleAction();
    onClose();
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text
              color={headingColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              {headerText ?? "Delete User Confirmation"}
            </Text>
          </ModalHeader>
          <ModalCloseButton
            borderRadius="50%"
            size="lg" // Custom size
            bg="brand.950"
            color="white"
            fontWeight="bold"
            _hover={{ bg: "brand.950", color: "white" }} //
          />
          <ModalBody>
            <Card direction="column" w="100%" px="10px">
              <Box bg={modalBgColor} borderRadius="20px" p="10" mb="40px">
                <Text
                  textAlign="center"
                  color={"white"}
                  fontSize="22px"
                  fontWeight="700"
                  lineHeight="100%"
                >
                  Are you sure you want to delete?
                </Text>
              </Box>
              <Flex gap="5">
                <Box flex="1">
                  <PrimayButton
                    onClick={handleConfirm}
                    width="100%"
                    key="yes"
                    label="Yes"
                  />
                </Box>
                <Box flex="1">
                  <PrimayButton
                    onClick={onClose}
                    width="100%"
                    key="no"
                    label="No"
                  />
                </Box>
              </Flex>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
