import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react";
import { FileUploadDropzone } from "./uploader";

export function BulkCreateRewardModal({ isOpen, onClose }) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
        <ModalOverlay />
        <ModalContent minHeight="70%" padding="6">
          <ModalCloseButton
            borderRadius="50%"
            size="lg"
            bg="brand.950"
            color="white"
            fontWeight="bold"
            _hover={{ bg: "brand.950", color: "white" }}
            top={-4}
            right={-4}
          />
          <FileUploadDropzone
            handleClose={onClose}
            label="Drag and Drop Here"
            description=".csv up to 5MB"
            // description=".csv, .xlsx, .xls up to 5MB"
            maxFileSize={5 * 1024 * 1024} // 5 MB
            acceptedFiles=".csv"
            // acceptedFiles=".xls,.xlsx,.csv"
          />
        </ModalContent>
      </Modal>
    </>
  );
}
