import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Card from "components/card/Card";
import { HSeparator } from "components/separator/Separator";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import { useDispatch } from "react-redux";
import { useAuthSelector } from "store/slices/auth";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomFormInput } from "components/customs/Input";
import { CustomHeading } from "components/customs/heading";
import { PrimayButton, UploadButton } from "components/customs/buttons";
import { schema } from "./schema";
import { updateProfileThunk } from "store/slices/auth";

export default function AdminProfile() {
  const dispatch = useDispatch();
  const auth = useAuthSelector();
  const user = auth?.user?.data?.user;
  const current_user_id = user?.id;

  const methods = useForm({
    defaultValues: {
      email: user?.email ?? "",
      username: user?.username ?? "",
      old_password: "",
      new_password: "",
    },
    resolver: yupResolver(schema),
  });

  const {
    watch,
    setError,
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty, dirtyFields },
  } = methods;

  function handleReset() {
    reset({
      email: user?.email ?? "",
      username: user?.username ?? "",
      old_password: "",
      new_password: "",
    });
  }

  useEffect(() => {
    handleReset();
  }, [auth]);

  const old_password = watch("old_password");
  useEffect(() => {
    if (old_password?.length === 0) {
      setError("new_password", {
        type: "manual",
        message: "",
      });
    }
  }, [old_password]);

  const onSubmit = async (data) => {
    if (!isDirty) return;
    const payload = Object.keys(data).reduce((acc, current) => {
      if (dirtyFields[current]) {
        acc[current] = data[current];
      }
      return acc;
    }, {});

    const res = await dispatch(
      updateProfileThunk({
        ...payload,
        user_id: current_user_id,
        role: user?.role,
      })
    );
    if (res.payload === API_SUCCESS_RESPONSE_TAG) {
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card mb={{ base: "0px", lg: "20px" }} align="center">
        <UploadButton
          label="Upload"
          user_id={current_user_id}
          showUploadBtn={true}
        />
        <Flex align="center" my="25px">
          <HSeparator />
        </Flex>

        <FormProvider {...methods}>
          <fieldset>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  lg: "repeat(2, 1fr)",
                }}
                gap={3}
              >
                <GridItem>
                  <CustomFormInput
                    {...{
                      placeholder: "Your email",
                      type: "email",
                      name: "email",
                      label: "Email",
                      isRequired: true,
                    }}
                  />
                </GridItem>
                <GridItem>
                  <CustomFormInput
                    {...{
                      placeholder: "Your username",
                      type: "text",
                      name: "username",
                      label: "Username",
                      isRequired: true,
                    }}
                  />
                </GridItem>
              </Grid>
              <Box>
                <Box my="3">
                  <CustomHeading text="Change Password" />
                </Box>
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    lg: "repeat(2, 1fr)",
                  }}
                  gap={3}
                >
                  <GridItem>
                    <CustomFormInput
                      {...{
                        placeholder: "Enter your old password",
                        type: "password",
                        name: "old_password",
                        label: "Old Password",
                        isRequired: true,
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <CustomFormInput
                      {...{
                        placeholder: "Enter your new password",
                        type: "password",
                        name: "new_password",
                        label: "New Password",
                        isRequired: true,
                      }}
                    />
                  </GridItem>
                </Grid>
              </Box>
              <PrimayButton
                label="Update Profile"
                isLoading={isSubmitting}
                disabled={!isDirty}
              />
            </form>
          </fieldset>
        </FormProvider>
      </Card>
    </Box>
  );
}
