import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LOCAL_STROAGE_USER_GET_KEY } from "lib/constants";
import { getAuthTokens } from "lib/helpers";

const CheckSigninContext = React.createContext();

const ROUTES = [
  "/auth/welcome",
  "/auth/welcome/",
  "/auth/sign-in",
  "/auth/sign-in/",
  "/auth/sign-up",
  "/auth/sign-up/",
  "/auth/forgot-password",
  "/auth/forgot-password/",
  "/auth/reset-password",
  "/auth/reset-password/",
];

export function CheckSigninProvider({ children }) {
  const history = useHistory();
  const pathname = history.location.pathname;
  const redirectToLogin = () => history && history.push("/admin/players");
  useEffect(() => {
    const token = getAuthTokens()?.accessToken;
    if (token && ROUTES.includes(pathname)) {
      redirectToLogin();
    }
  }, [history]);

  return (
    <CheckSigninContext.Provider value={{}}>
      {children}
    </CheckSigninContext.Provider>
  );
}
