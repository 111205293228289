import { useMemo } from "react";
import * as yup from "yup";

export function useFormSchema({ isAdmin }) {
  return useMemo(() => {
    return yup.object().shape({
      // email: yup
      //   .string()
      //   .trim()
      //   .required("Email is required.")
      //   .email("Please enter a valid email."),
      // name: yup.string().required("Name is required."),

      password: yup
        .string()
        .trim()
        .when({
          is: (val) => val && val.length > 1, // Trigger validation only if password has more than one character
          then: (schema) =>
            yup
              .string()
              .trim()
              .min(8, "Password must contain at least 8 characters")
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character"
              )
              .required("Password is a required field"),
          otherwise: (schema) => yup.string().optional(),
        }),
      confirmPassword: yup.string().when("password", {
        is: (val) => val && val.length > 1, // Trigger validation only if password has more than one character
        then: (schema) =>
          yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Confirm password is required"),
        otherwise: (schema) => yup.string().optional(),
      }),
      username: yup
        .string()
        .trim()
        .min(3, "Username at least 3 characters long.")
        .max(12, "Username cannot have characters more than 12.")
        .required("Username is required"),
      // password: yup
      //   .string()
      //   .trim()
      //   .min(8, "Password must contain at least 8 characters")
      //   .matches(
      //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      //     "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character"
      //   )
      //   .required("Password is a required field"),
      // user_id: yup
      //   .string()
      //   // .matches(
      //   //   /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
      //   //   "Please enter a valid UUID."
      //   // )
      //   .required("Player's id is required."),
      acorns: yup.number().when([], {
        is: () => !isAdmin,
        then: () =>
          yup
            .number()
            .typeError("Only numbers are allowed.")
            .required("Player's acorns is required."),
      }),
      golden_acorns: yup.number().when([], {
        is: () => !isAdmin,
        then: () =>
          yup
            .number()
            .typeError("Only numbers are allowed.")
            .required("Player's acorns is required."),
      }),
    });
  }, [isAdmin]);
}

// export const schema = yup.object().shape({
//   email: yup
//     .string()
//     .trim()
//     .required("Player's email is required.")
//     .email("Please enter a valid email."),
//   name: yup.string().required("Player's name is required."),
//   username: yup.string().required("Player's username is required."),
//   user_id: yup
//     .string()
//     // .matches(
//     //   /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
//     //   "Please enter a valid UUID."
//     // )
//     .required("Player's id is required."),
//   rank: yup
//     .number()
//     .typeError("Only numbers are allowed.")
//     .required("Player's rank is required."),
//   golden_acrons: yup
//     .number()
//     .typeError("Only numbers are allowed.")
//     .required("Player's highest score is required."),
// });
