import { Box, Flex, SimpleGrid, Spinner } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchLeaderboardThunk,
  useLeaderboardSelector,
} from "store/slices/leaderboard";
import { leaderboardColumns } from "./leaderboardColumns";
import { DATA_FETCHING_STATUSES } from "lib/constants";
import { LeaderboardTable } from "./leaderboardTable";
import { formatDate } from "lib/helpers";
import { formatGameTime } from "lib/helpers";
// import { useColors } from "components/customs/colors";

export function Leaderboard() {
  // const { textColor } = useColors();
  const dispatch = useDispatch();
  const leaderboardState = useLeaderboardSelector();
  const leaderboard = leaderboardState?.leaderboard;
  const totalPages = leaderboard?.data?.totalPages;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(fetchLeaderboardThunk({ page: currentPage }));
  }, [currentPage, dispatch]);


  const tableRows = useMemo(() => {
    const results = leaderboard?.data?.results ?? [];
    return results
      // .slice()
      // .sort((a, b) => {
      //   if (a?.score === b?.score && a?.gameTime < b?.gameTime) return -1;
      //   if (a?.score === b?.score && a?.gameTime > b?.gameTime) return 1;
      //   if (a?.score > b?.score) return -1;
      //   if (a?.score < b?.score) return 1;
      //   return 0;
      // })
      .map((boardItem, index) => ({
        id: boardItem.id,
        rank: index + 1,
        gameTime: formatGameTime(boardItem.gameTime),
        name: boardItem?.userId?.name ?? boardItem?.userId?.username ?? "",
        player_id: boardItem?.userId?.id ?? "N/A",
        score: boardItem?.score ?? "N/A",
        joined_date: boardItem?.gameDate
          ? formatDate(new Date(boardItem?.gameDate))
          : "N/A",
      }));
  }, [leaderboard]);

  let component = (
    <Flex justifyContent="center" mt="28">
      <Spinner size="xl" color="brand.s" />
    </Flex>
  );

  if (leaderboard?.status === DATA_FETCHING_STATUSES.DATA_LOADED) {
    component = (
      <LeaderboardTable
        columnsData={leaderboardColumns}
        tableData={tableRows}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mb="20px" spacing={{ base: "20px", xl: "20px" }}>
        {component}
      </SimpleGrid>
    </Box>
  );
}
