// Chakra Imports
import {
  Avatar,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  Box,
  Center,
} from "@chakra-ui/react";
import { MdNotificationsNone } from "react-icons/md";
// Custom Components
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React from "react";
// Assets
import { FaEthereum } from "react-icons/fa";
import routes from "routes.js";
import { persistor } from "store";
import { useHistory } from "react-router-dom";
import { useAuthSelector } from "store/slices/auth";
import { BACKEND_BASE_URL } from "lib/constants";
import { useHasNewNotificationSelector } from "store/slices/notifications";
import { useNotificationsCountSelector } from "store/slices/notifications";
import { useDispatch } from "react-redux";
import { setUser } from "store/slices/auth";
import { LOCAL_STROAGE_USER_GET_KEY } from "lib/constants";
export default function HeaderLinks(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const hasNewNotification = useHasNewNotificationSelector();

  const { secondary } = props;
  // Chakra Color Mode
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const notificationsCount = useNotificationsCountSelector();

  const auth = useAuthSelector();
  const user = auth?.user?.data?.user;
  const name = user?.name ?? user?.username ?? "";
  const profilePicture = user?.profile_picture
    ? `${BACKEND_BASE_URL}/${user.profile_picture}`
    : "";
  const redirectToProfile = () => history.push("/admin/admin-profile");
  const redirectToLogin = () => history.push("/auth/sign-in");

  const handleLogout = () => {
    dispatch(setUser({data:null}))
    persistor.flush().then(() => {
      persistor.purge();
      redirectToLogin();
    });
  };

  const handleBellIconClick = (e) => {
    e.preventDefault();
    history.push("/admin/notifications");
  };

  return (
    <Flex gap={2} justifyContent={{ sm: "end", md: "start" }}>
      <Flex
        w={{ sm: "auto", md: "auto" }}
        alignItems="center"
        flexDirection="row"
        bg={menuBg}
        flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
        p="10px"
        borderRadius="30px"
        boxShadow={shadow}
      >
        <Box
          size="sm"
          w="40px"
          h="40px"
          onClick={handleBellIconClick}
          cursor={"pointer"}
        >
          <Center axis="both" h="100%" position="relative">
            <Icon
              as={MdNotificationsNone}
              color={"#D0BEA3"}
              w="25px"
              h="25px"
            />
            {(notificationsCount > 0) && (
              <Box
                borderRadius="50%"
                background="navy.700"
                height="25px"
                minWidth="25px"
                padding="0 6px"
                position="absolute"
                top="-5%"
                right="-5%"
                fontWeight="bold"
                fontSize="12px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="white"
              >
                {notificationsCount > 9 ? "9+" : notificationsCount}
              </Box>
            )}
          </Center>
        </Box>
      </Flex>
      <Flex
        w={{ sm: "100%", md: "auto" }}
        alignItems="center"
        flexDirection="row"
        bg={menuBg}
        flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
        p="10px"
        borderRadius="30px"
        boxShadow={shadow}
      >
        <Flex
          bg={ethBg}
          display={secondary ? "flex" : "none"}
          borderRadius="30px"
          ms="auto"
          p="6px"
          align="center"
          me="6px"
        >
          <Flex
            align="center"
            justify="center"
            bg={ethBox}
            h="29px"
            w="29px"
            borderRadius="30px"
            me="7px"
          >
            <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
          </Flex>
          <Text
            w="max-content"
            color={ethColor}
            fontSize="sm"
            fontWeight="700"
            me="6px"
          >
            1,924
            <Text as="span" display={{ base: "none", md: "unset" }}>
              {" "}
              ETH
            </Text>
          </Text>
        </Flex>
        <SidebarResponsive routes={routes} />

        <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: "pointer" }}
              color="white"
              name={name}
              src={profilePicture}
              bg={profilePicture ? "transparent" : "brand.950"}
              size="sm"
              w="40px"
              h="40px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex w="100%" mb="0px">
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                👋&nbsp; Hey, {name}
              </Text>
            </Flex>
            <Flex flexDirection="column" p="10px" color="brand.950">
              <MenuItem
                onClick={redirectToProfile}
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                borderRadius="8px"
                px="14px"
              >
                <Text fontSize="sm">Profile</Text>
              </MenuItem>
              <MenuItem
                onClick={handleLogout}
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                color="red.400"
                borderRadius="8px"
                px="14px"
              >
                <Text fontSize="sm">Log out</Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
