import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useAuthSelector } from "store/slices/auth";
import { ROLES } from "./constants";

export const useScrollToEnd = (dependencyArray) => {
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView(true);
      // containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [...dependencyArray, containerRef.current]);

  return { containerRef };
};

export function useBlockRoute({ routesKeyWords = [], role = [] }) {
  const history = useHistory();
  const auth = useAuthSelector();
  const user = auth?.user?.data?.user;
  const userRole = user?.role ?? "";
  if (
    !(
      routesKeyWords.some((r) => history?.location?.pathname?.includes(r)) &&
      role.some((r) => userRole === r)
    )
  ) {
    // history.push("/admin/players");
  }
}

export function useProtectedAdminRoutes() {
  useBlockRoute({
    role: [ROLES.SUPER_ADMIN],
    routesKeyWords: ["admins", "invite-admin"],
  });
}
