// Chakra imports
import {
  background,
  Box,
  Flex,
  HStack,
  SimpleGrid,
  Spinner,
  Tag,
  TagLabel,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useAuthSelector } from "store/slices/auth";
import { useDispatch } from "react-redux";
import { fetchUsersThunk } from "store/slices/auth";
import { usersTableColumns } from "./usersColumns";
import { DATA_FETCHING_STATUSES } from "lib/constants";
import { UsersTable } from "./UsersTable";
import { useColors } from "components/customs/colors";
import { useHistory, useLocation } from "react-router-dom";
import { deleteUserThunk } from "store/slices/auth";
import { useLoadingSelector } from "store/slices/loading";
import { formatDate } from "lib/helpers";
import { useSearchParams } from "./user";
import { useProtectedAdminRoutes } from "lib/hooks";
import { ConfirmationModal } from "components/customs/confirmationModal";
import { CustomFormInput } from "components/customs/Input";
import { useDebounceValue } from 'usehooks-ts'


export function Users() {
  useProtectedAdminRoutes();
  const { textColor } = useColors();
  const history = useHistory();
  const location = useLocation();
  const { searchParams } = useSearchParams();
  const role = searchParams.get("role");
  const dispatch = useDispatch();
  const auth = useAuthSelector();
  const users = auth?.users;
  const totalPages = users?.data?.totalPages;
  const loading = useLoadingSelector();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useDebounceValue(search, 500)


  useEffect(() => {
    dispatch(fetchUsersThunk({ page: currentPage, role, search: debouncedSearch  }));
  }, [currentPage, debouncedSearch]);

  function handleSearch(value) {
    setSearch(value)
    setDebouncedSearch(value)
  }

  function handleEdit({ id }) {
    // someties location.pathname trailing slash which makes the url incorect as a whole with double slashes
    history.push(
      `${location.pathname}/edit?id=${id}&role=${role}`.replace(
        /([^:]\/)\/+/g,
        "$1"
      )
    );
  }

  function handleView({ id }) {
    // someties location.pathname trailing slash which makes the url incorect as a whole with double slashes
    history.push(
      `${location.pathname}/view?id=${id}&role=${role}`.replace(
        /([^:]\/)\/+/g,
        "$1"
      )
    );
  }

  function handleViewAchievements({ id }) {
    // someties location.pathname trailing slash which makes the url incorect as a whole with double slashes
    history.push(
      `${location.pathname}/achievements?id=${id}`.replace(/([^:]\/)\/+/g, "$1")
    );
  }

  function handleDelete({ id }) {
    const user = users?.data?.results.find((u) => u.id === id);
    dispatch(deleteUserThunk({ id, role: user.role }));
  }

  function handleAction({ action, id }) {
    if (loading) return;

    if (action === "View") {
      handleView({ id });
      return;
    }

    if (action === "Achievements") {
      handleViewAchievements({ id });
      return;
    }

    if (action === "Edit") {
      handleEdit({ id });
      return;
    }

    if (action === "Delete") {
      onOpen();
      setSelectedRow(id);
    }
  }

  const columns = useMemo(() => usersTableColumns(role), [role]);

  const tableRows = useMemo(
    () =>
      users?.data?.results?.map((user) => {
        return {
          id: user?.id,
          username: user?.username ?? "N/A",
          email: user.email ?? "",
          last_login: user?.last_login
            ? formatDate(new Date(user?.last_login))
            : "N/A",
          last_played: user?.last_played
            ? formatDate(new Date(user?.last_played))
            : "N/A",
          actions: (
            <HStack spacing={4}>
              {["Achievements", "View", "Edit", "Delete"].map((value) => {
                if (role === "admins" && value === "Achievements") {
                  return null;
                }
                return (
                  <Tag
                    disabled={loading}
                    _disabled={{ opacity: 0.8, cursor: "not-allowed" }}
                    onClick={(e) =>
                      handleAction({ event: e, action: value, id: user.id })
                    }
                    size="lg"
                    key={value}
                    variant="solid"
                    sx={{ backgroundColor: "secondaryGray.900" }}
                    _hover={{ cursor: "pointer" }}
                  >
                    {/* <TagLeftIcon boxSize='12px' as={AddIcon} /> */}
                    <TagLabel>{value}</TagLabel>
                  </Tag>
                );
              })}
            </HStack>
          ),
        };
      }),
    [auth, loading]
  );

  let component = (
    <Flex justifyContent="center" mt="28">
      <Spinner size="xl" color="brand.950" />
    </Flex>
  );
  if (users?.status === DATA_FETCHING_STATUSES.DATA_LOADED) {
    component = (
      <UsersTable
        role={role}
        search={search}
        handleSearch={handleSearch}
        columnsData={columns}
        tableData={tableRows}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    );
  }
  return (
    <>
      {isOpen && (
        <ConfirmationModal
          {...{
            isOpen,
            onOpen,
            onClose: () => {
              setSelectedRow("");
              onClose();
            },
            handleAction: () => handleDelete({ id: selectedRow }),
          }}
        />
      )}
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid mb="20px" spacing={{ base: "20px", xl: "20px" }}>
          {component}
        </SimpleGrid>
      </Box>
    </>
  );
}
