export const rewardsTableColumns = [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Entry Fee",
    accessor: "entry_fee",
  },
  {
    Header: "Golden Acorns Limit",
    accessor: "golden_acorns_limit",
  },
  
  
];
