import { Avatar, Box, Button, Spinner, Flex, Icon } from "@chakra-ui/react";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useAuthSelector } from "store/slices/auth";
import { updateProfilePictureThunk } from "store/slices/auth";
import avatar from "assets/img/avatars/avatar4.png";
import { BACKEND_BASE_URL } from "lib/constants";
import { useColors } from "./colors";
import { MdOutlineCloudUpload } from "react-icons/md";

export function PrimayButton({
  label,
  isLoading,
  type,
  disabled,
  onClick,
  ...restProps
}) {
  const bgButtonGradient = "linear-gradient(180deg, #FFCE88 0%, #DD9122 100%)";

  return (
    <Button
      {...(onClick && { onClick })}
      disabled={disabled}
      isLoading={isLoading}
      type={type ?? "submit"}
      fontSize="lg"
      variant="brand"
      fontWeight="700"
      bg={bgButtonGradient}
      _hover={{ opacity: 0.8 }}
      {...restProps}
    >
      {label || "Click Me"}
    </Button>
  );
}

export function UploadButton({ label, showUploadBtn, currentUser }) {
  const [inputRef, setInputRef] = useState(null);
  const bgButtonGradient = "linear-gradient(180deg, #FFCE88 0%, #DD9122 100%)";
  const dispatch = useDispatch();
  const auth = useAuthSelector();
  const user = auth?.user?.data?.user;
  const { borderColor } = useColors();
  const [isUploading, setIsUploading] = useState(false);

  const user_id = currentUser ? currentUser.id : user.id

  const [avatarSrc, setAvatarSrc] = useState(
    () => {
      if(currentUser) {
        return `${BACKEND_BASE_URL}/${currentUser?.profile_picture}` || avatar
      }
      return `${BACKEND_BASE_URL}/${user?.profile_picture}` || avatar
    }
  );

  function handleSelectFile() {
    if (!inputRef) return;
    inputRef.click();
  }

  async function handleFileChange(e) {
    setIsUploading(true);

    const file = e.target.files[0];
    e.target.value = null;
    if (file && file.size > 5 * 1024 * 1024) {
      // 5MB in bytes
      toast.error("File size exceeds 5MB. Please upload a smaller file.");
      setIsUploading(false);
      return;
    }

    const res = await dispatch(updateProfilePictureThunk({ file, user_id }));
    if (res?.payload?.responseStatus === API_SUCCESS_RESPONSE_TAG) {
      try {
        const newImageUrl = `${BACKEND_BASE_URL}/${res?.payload?.data?.user?.profile_picture}`;
        // Preload the image before setting it to avoid flickering
        const img = new Image();
        img.src = newImageUrl;
        img.onload = () => {
          setAvatarSrc(newImageUrl);
          setIsUploading(false);
          toast.success("Profile picture updated successfully");
        };
      } catch (error) {
        console.error("File upload failed:", error);
        setIsUploading(false);
      }
    }
  }

  return (
    <>
      <Flex justifyContent={"center"}>
        <Box position="relative" display="flex" w="145px" h="145px">
          <Avatar
            src={avatarSrc}
            h="145px"
            w="145px"
            border="4px solid"
            borderColor={borderColor}
            opacity={isUploading ? 0.7 : 1}
          />
          {isUploading && (
            <Spinner
              size="xl"
              color={borderColor}
              thickness="4px"
              position="absolute"
              top="35%"
              left="35%"
            />
          )}
        </Box>
      </Flex>
      {showUploadBtn && (
        <Box>
          <input
            type="file"
            accept=".png, .jpeg, .jpg"
            hidden
            ref={setInputRef}
            onChange={handleFileChange}
          />
          <Button
            onClick={handleSelectFile}
            fontSize="sm"
            variant="brand"
            fontWeight="700"
            bg={bgButtonGradient}
            _hover={{ opacity: 0.8 }}
          >
            {label}
          </Button>
        </Box>
      )}
    </>
  );
}

export const UploadIconButton = ({ onClick }) => (
  <Icon fontSize="20px" color="brand.950" onClick={onClick} cursor="pointer">
    <MdOutlineCloudUpload />
  </Icon>
);
