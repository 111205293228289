import React, { useEffect, useState,useMemo } from "react";
import {
  Box,
  Flex,
  useColorModeValue,
  GridItem,
  Grid,
  Spinner,
  useMediaQuery,
  useDisclosure,
} from "@chakra-ui/react";
import { useAuthSelector } from "store/slices/auth";
import { useDispatch } from "react-redux";
import Card from "components/card/Card";
import { useLoadingSelector } from "store/slices/loading";
import { SOCKET_EVENTS } from "lib/constants";
import { useSocketContext } from "contexts/SocketContext";
import { DATA_FETCHING_STATUSES } from "lib/constants";
import { fetchUsersThunk } from "store/slices/auth";
import { CustomNoDataFound } from "components/customs/noDataFound";
import { useInView } from "react-hook-inview";
import { useScrollToEnd } from "lib/hooks";
import { fetchChatsThunk } from "store/slices/chats";
import { useChatsSelector } from "store/slices/chats";
import { ChatForm } from "./chatForm";
import { Messages } from "./messages";
import { SearchBar } from "components/customs/Input";
import { useDebounceValue } from "usehooks-ts";
import { useChatUsersSelector, setAllUsers } from "store/slices/chatUsers";
import { CHAT_USERS_ACTION_TYPE } from "store/slices/auth";
import { UserList } from "./userListSidebar";
import { markChatReadThunk } from "store/slices/chats";
import { setUpdateChatCount } from "store/slices/chatUsers";
import { BroadCastModal } from './broadCastModal';
import { PrimayButton } from 'components/customs/buttons';

function Chat() {

  const {
    isOpen: isOpenBroadcastModal,
    onOpen: onOpenBroadcastModal,
    onClose: onCloseBroadcastModal,
  } = useDisclosure();

  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "#D0BEA3";
  const [messages, setMessages] = useState([]);
  const [selectedUserId, setselectedUserId] = useState("");
  const dispatch = useDispatch();
  const loading = useLoadingSelector();
  const auth = useAuthSelector();
  const users = auth?.users;
  const totalPages = users?.data?.totalPages;
  const user = auth?.user?.data?.user;
  const chatState = useChatsSelector();
  const chats = chatState?.chats?.data?.results ?? [];
  const [search, setSearch] = useState("");
  const [isBroadcast, setIsBroadcast] = useState(false)
  const [debouncedSearch, setDebouncedSearch] = useDebounceValue(search, 500);
  const [page, setPage] = useState(1);

  const allUsers = useChatUsersSelector();

  const { socket } = useSocketContext();
  const { containerRef } = useScrollToEnd([messages, selectedUserId, chats]);
  const [ref, isVisible] = useInView({
    threshold: 1,
    // rootMargin: 20,
  });

  const hasMore = useMemo(() => page < totalPages, [page, totalPages]);

  // const selectedUser = users?.data?.results?.find(
  //   (u) => u.id === selectedUserId
  // );

  function handleSearch(value) {
    setSearch(value);
    setDebouncedSearch(value);
    setAllUsers([]); // Clear the current user list
    setPage(1);
    setselectedUserId("");
  }

    function updateMessages({ message }) {
      if (
        message.senderId === selectedUserId ||
        message.senderId === user.id ||
        (message.senderRole !== "user" && message.receiverId === selectedUserId)
        || isBroadcast

      ) {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
      // dispatch(
      //   fetchChatsThunk({
      //     id: selectedUserId,
      //   })
      // );
      // dispatch(setUpdateChat({ newMessage: message }));
    }

  useEffect(() => {
    if (isVisible && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [isVisible, hasMore]);

  useEffect(() => {
    dispatch(
      fetchChatsThunk({
        id: selectedUserId,
      })
    );
  }, [page, selectedUserId]);

  useEffect(() => {
    dispatch(
      fetchUsersThunk({
        page,
        type:'chat',
        role: "user",
        searchType: "chat",
        search: debouncedSearch,
      })
    );
  }, [isVisible, page, selectedUserId]);

  useEffect(() => {
    if (totalPages === undefined || totalPages === 0) {
      dispatch(
        fetchUsersThunk({
          page: 1,
          type:'chat',
          role: "user",
          searchType: "chat",
          search: debouncedSearch,
        })
      );
    }
  }, [totalPages, dispatch]);

  useEffect(() => {
    dispatch(
      fetchUsersThunk({
        page: 1,
        role: "user",
        type:'chat',
        searchType: "chat",
        search: debouncedSearch,
        actionType: CHAT_USERS_ACTION_TYPE,
      })
    );
  }, [debouncedSearch]);

  useEffect(() => {
    setMessages(chats);
  }, [chatState]);

  useEffect(() => {
    if (
      !!selectedUserId &&
      users?.status === DATA_FETCHING_STATUSES.DATA_LOADED
    ) {
      // socket?.emit(SOCKET_EVENTS.MARK_CHAT_AS_READ, {
      //   userId: selectedUserId
      // })
      dispatch(markChatReadThunk({ selectedUserId }));
    }
  }, [selectedUserId]);

  useEffect(() => {
    if (!socket) return;
    const handleReceiveMessage = (message) => {
      if (message.senderId !== selectedUserId) {
        // update the msg count for that user
        dispatch(setUpdateChatCount({ senderId: message.senderId }));
      }

      if (message.senderId === selectedUserId) {
        dispatch(markChatReadThunk({ selectedUserId }));
      }

      updateMessages({ message });
    };

    socket.on(SOCKET_EVENTS.RECEIVE_MESSAGE, handleReceiveMessage);
    return () => {
      socket.off(SOCKET_EVENTS.RECEIVE_MESSAGE, handleReceiveMessage);
    };
  }, [socket, selectedUserId]);

  if (users?.status !== DATA_FETCHING_STATUSES.DATA_LOADED) {
    return (
      <Flex justifyContent="center" mt="28">
        <Spinner size="xl" color="brand.950" />
      </Flex>
    );
  }

  return (
    <>
      <BroadCastModal
        isOpen={isOpenBroadcastModal}
        onClose={onCloseBroadcastModal}
        updateMessages= {updateMessages}
        setIsBroadcast={setIsBroadcast}
      />
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card p="0">
          <Grid templateColumns="repeat(12, 1fr)">
            <GridItem colSpan={3} bg={textColorSecondary}>
              <Box height="700px" overflow="scroll">
                <Box bg="white" position="sticky" top="0px" zIndex={99}>
                  <SearchBar
                    {...{
                      search,
                      handleSearch,
                      styles: {
                        borderRadius: "none",
                        borderTopLeftRadius: "lg",
                        borderTopRightRadius: "lg",
                      },
                    }}
                  />
                  <Box
                    cursor='pointer'
                    textAlign='center'
                    color='white'
                    fontSize='xl'
                    fontWeight='700'
                    paddingY='8px'
                    onClick={()=> {
                      setIsBroadcast(true)
                      onOpenBroadcastModal()
                    }}
                  >
                    <PrimayButton
                      label='Broadcast'
                      style={{
                        height: '50px',
                        color: '#000',
                        width: '100%',
                        borderRadius: 0,
                        background:
                          'linear-gradient(180deg, #FFE195 0%, #F6B408 100%',
                      }}
                    />
                  </Box>
                </Box>
                <UserList
                  ref={ref}
                  hasMore={hasMore}
                  allUsers={allUsers}
                  selectedUserId={selectedUserId}
                  setselectedUserId={setselectedUserId}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={9}>
              <Box
                sx={{
                  position: "relative",
                  height: {
                    sm: "calc(100vh - 165px)",
                    md: "calc(100vh - 130px)",
                  },
                  padding: { sm: "0px 5px", md: "25px 0px" },
                }}
              >
                <Box spacing={5} p={5} height="73vh" overflowY="scroll">
                  {selectedUserId ? (
                    <>
                      <Messages
                        messages={messages}
                        userID={user?.id}
                        selectedUserId={selectedUserId}
                      />
                      <Box ref={containerRef}></Box>
                    </>
                  ) : (
                    <CustomNoDataFound value="Please select a user." />
                  )}
                </Box>
                <ChatForm
                  key={selectedUserId}
                  receiverId={selectedUserId}
                  senderId={user.id}
                  updateMessages={updateMessages}
                  currentAdmin={user}
                />
              </Box>
            </GridItem>
          </Grid>
        </Card>
      </Box>
    </>
  );
}

export default Chat;
