import React, { useEffect } from 'react';
import {
  FormControl,
  Box,
  Flex,
  Heading,
  Text,
  useColorModeValue,
  Spinner,
  FormErrorMessage,
} from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import { useAuth } from 'contexts/AuthContext';
import { useAuthSelector } from 'store/slices/auth';
import { useDispatch } from 'react-redux';
import { API_SUCCESS_RESPONSE_TAG } from 'lib/constants';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Card from 'components/card/Card';
import { PrimayButton } from 'components/customs/buttons';
import { useProtectedAdminRoutes } from 'lib/hooks';
import { CustomFormInput } from 'components/customs/Input';
import {
  fetchGameEntryFeeThunk,
  updateGameEntryFeeThunk,
  useGameSettingsSelector,
} from 'store/slices/auth';
import { DEFAULT_ENTRY_FEE } from 'store/slices/auth';
import { DATA_FETCHING_STATUSES } from 'lib/constants';
import { usePushNotficationSelector } from 'store/slices/auth';
import { sendPushNotification } from 'store/slices/auth';

function PushNotifications() {
  // useProtectedAdminRoutes();
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = '#D0BEA3';

  const dispatch = useDispatch();
  const auth = useAuthSelector();
  const pushNotification = usePushNotficationSelector();
  //   const entryFee = pushNotification?.data?.settings?.entryFee ?? DEFAULT_ENTRY_FEE;
  const history = useHistory();

  const schema = yup.object().shape({
    title: yup
      .string()
      .max(40, 'Title must be under 40 characters')
      .required('Title is required'),
    message: yup
      .string()
      .min(10, 'Message must be at least 10 characters')
      .max(150, 'Message must be under 150 characters')
      .required('Message is required'),
  });

  const methods = useForm({
    defaultValues: {
      title: '',
      message: '',
    },
    resolver: yupResolver(schema),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = methods;

  console.log('isDirty=>>', isDirty);
  const onSubmit = async (data) => {
    const res = await dispatch(
      sendPushNotification({ title: data.title, message: data.message })
    );

    if (res.payload === API_SUCCESS_RESPONSE_TAG) {
      methods.reset();
    }
  };

  //   useEffect(() => {
  //     dispatch(fetchGameEntryFeeThunk({}));
  //   }, []);

  function handleReset() {
    reset({
      title: '',
      message: '',
    });
  }

  useEffect(() => {
    handleReset();
  }, [pushNotification]);

  //   if (settingsState?.status !== DATA_FETCHING_STATUSES.DATA_LOADED) {
  //     return (
  //       <Flex justifyContent="center" mt="28">
  //         <Spinner size="xl" color="brand.950" />
  //       </Flex>
  //     );
  //   }
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card
        mb={{ base: '0px', lg: '20px' }}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Heading
            textAlign='center'
            color={textColor}
            fontSize='36px'
            mb='10px'
          >
            Broadcast Message
          </Heading>

        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: '100%', md: '420px' }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: 'auto', lg: 'unset' }}
          me='auto'
          mb={{ base: '20px', md: 'auto' }}
        >
          <Flex align='center' mb='25px'>
            <HSeparator />
          </Flex>
          <FormProvider {...methods}>
            <fieldset disabled={isSubmitting}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <FormControl isInvalid={!!methods.formState.errors.title}>
                  <Box mb='24px'>
                    <CustomFormInput
                      {...{
                        placeholder: 'Title',
                        type: 'text',
                        name: 'title',
                        label: 'Title',
                        isRequired: true,
                      }}
                    />
                  </Box>
                </FormControl>

                <FormControl isInvalid={!!methods.formState.errors.message}>
                  <Box mb='24px'>
                    <CustomFormInput
                      {...{
                        placeholder: 'Write a message',
                        type: 'text',
                        name: 'message',
                        label: 'Message',
                        isRequired: true,
                      }}
                    />
                  </Box>
                </FormControl>

                <PrimayButton
                  disabled={!methods.formState.isDirty}
                  label='Send'
                  isLoading={isSubmitting}
                  w='100%'
                />
              </form>
            </fieldset>
          </FormProvider>
        </Flex>
      </Card>
    </Box>
  );
}

export default PushNotifications;