import { ArrowLeftIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

export function BackButton() {
  const history = useHistory();
  return (
    <Flex
      cursor={"pointer"}
      alignItems={"center"}
      gap={1}
      color={"navy.700"}
      onClick={() => history.goBack()}
    >
      <ArrowLeftIcon fontSize={10} />
      <Text fontSize="12px" fontWeight="600">
        Back
      </Text>
    </Flex>
  );
}
