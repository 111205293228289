// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DATA_FETCHING_STATUSES } from "lib/constants";
import axiosPrivate from "lib/config/axios";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import { ENDPOINTS } from "lib/constants";
import { setUsers } from "./auth";
import { setAllUsers } from "./chatUsers";

export const fetchChatsThunk = createAsyncThunk(
  "appChats/fetchChats",
  async ({ id }, { dispatch }) => {
    try {
      const response = await axiosPrivate.get(`${ENDPOINTS.CHATS}/${id}`, {
        params: {
          createdAt: "desc",
          limit: 1000,
        },
      });
      const chats = response.data;
      dispatch(
        setChats({
          data: chats,
          status: DATA_FETCHING_STATUSES.DATA_LOADED,
        })
      );
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message?.toLowerCase() === "not found") {
        toast.error(error?.response?.data?.message ?? "Something went wrong");
      } else {
        toast.error("Something went wrong");
      }
    }
  }
);

export const markChatReadThunk = createAsyncThunk(
  "appChats/markChatRead",
  async ({ selectedUserId }, { dispatch, getState }) => {
    try {
      await axiosPrivate.patch(
        `${ENDPOINTS.USERS}/${selectedUserId}/mark-all-chats-as-read`,
        {}
      );
      // const { status, ...users } = getState().authReducer.users;
      const users = getState().chatUsersReducer.allUsers;
      // const updatedUsers = users.data.results.map((u) => {
      const updatedUsers = users.map((u) => {
        if (u.id !== selectedUserId) return u;
        return {
          ...u,
          unreadChats: 0,
        };
      });

      dispatch(setAllUsers({ usersList: updatedUsers }));
      dispatch(
        setUsers({
          data: {
            ...users.data,
            results: updatedUsers,
          },
          status: DATA_FETCHING_STATUSES.DATA_LOADED,
        })
      );
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message?.toLowerCase() === "not found") {
        toast.error(error?.response?.data?.message ?? "Something went wrong");
      } else {
        toast.error("Something went wrong");
      }
    }
  }
);

const initialState = {
  chats: {
    status: DATA_FETCHING_STATUSES.DATA_LOADING,
    data: [],
  },
};

export const chatsSlice = createSlice({
  name: "chatsSlice",
  initialState,
  reducers: {
    setChats: (state, action) => {
      state.chats = {
        ...state.chats,
        ...action.payload,
      };
    },
    setUpdateChat: (state, action) => {
      state.chats = {
        ...state.chats,
        data: {
          ...state.chats.data,
          results: [...state.chats.data.results, action.payload.newMessage],
        },
      };
    },
  },
});
export const { setChats, setUpdateChat } = chatsSlice.actions;

export default chatsSlice.reducer;

export const updateChat = ({ newMessage }) => {
  console.log(newMessage);
  setUpdateChat({
    newMessage,
  });
};

//Selector
export const useChatsSelector = () =>
  useSelector((state) => {
    return state.chatsReducer;
  });
