import * as yup from "yup";

export const schema = yup.object().shape({
  email: yup.string().trim().optional().email("Please enter a valid email."),
  username: yup
    .string()
    .trim()
    .min(3, "Username at least 3 characters long.")
    .max(12, "Username cannot have characters more than 12.")
    .required("Username is required"),
  old_password: yup.string().optional(),
  new_password: yup.string().when("old_password", {
    is: (val) => val && val.length > 0, // Check if old_password has a value
    then: () =>
      yup
        .string()
        .trim()
        .min(8, "Password must contain at least 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character"
        )
        .required("New password is required when changing the password."),
    otherwise: () => yup.string().optional(),
  }),
});
