import { LOCAL_STROAGE_USER_GET_KEY } from "lib/constants";
import { getAuthTokens } from "lib/helpers";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuthSelector } from "store/slices/auth";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const auth = useAuthSelector();
  const tokensFromState = auth?.user?.data?.tokens;
  const tokens = getAuthTokens();

  const accessToken = tokensFromState?.access?.token || tokens?.accessToken;
  const refreshToken = tokensFromState?.refresh?.token || tokens?.refreshToken;
  const isAuthenticated =
    !!tokens?.accessToken || !!tokensFromState?.access?.token;
  console.log("isAuthenticated", isAuthenticated);
  return (
    <AuthContext.Provider
      value={{ isAuthenticated, refreshToken, accessToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
