import React from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { signUpThunk } from "store/slices/auth";
import { useHistory } from "react-router-dom";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSearchParams } from "views/admin/users/user";

const schema = yup.object().shape({
  // email: yup
  //   .string()
  //   .trim()
  //   .email("Please enter a valid email.")
  //   .required("Email is required."),
  password: yup
    .string()
    .trim()
    .min(8, "Password must contain at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character"
    )
    .required("Password is a required field"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
  name: yup
    .string()
    .trim()
    .min(3, "Name at least 3 characters long.")
    .required("Name is required."),
  username: yup
    .string()
    .trim()
    .min(3, "Username at least 3 characters long.")
    .max(12, "Username cannot have characters more than 12.")
    .required("Username is required"),
});

function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "#D0BEA3";
  const inputColor = "#75582B";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const bgButtonGradient = "linear-gradient(180deg, #FFCE88 0%, #DD9122 100%)";

  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const { searchParams } = useSearchParams();
  const inviteCode = searchParams.get("inviteCode");
  const history = useHistory();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      // email: "",
      password: "",
      username: "",
      name: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ email, password, username, name }) => {
    const res = await dispatch(
      signUpThunk({
        email,
        password,
        username,
        name,
        role: "admin",
        inviteCode,
      })
    );
    if (res.payload === API_SUCCESS_RESPONSE_TAG) {
      history.push("/auth/sign-in");
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign up to manage
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your details below
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Flex align="center" mb="25px">
            <HSeparator />
          </Flex>
          <fieldset disabled={isSubmitting}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isInvalid={errors.name}>
                <Box mb="24px">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Full Name<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    {...register("name")}
                    id="name"
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    placeholder="Your name"
                    fontWeight="500"
                    size="lg"
                    _placeholder={{ color: "#75582B" }}
                    borderColor="#75582B"
                    color="#75582B"
                  />
                  <FormErrorMessage>
                    {errors.name && errors.name.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>

              <FormControl isInvalid={errors.username}>
                <Box mb="24px">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Username<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    {...register("username")}
                    id="username"
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    placeholder="Your username"
                    fontWeight="500"
                    size="lg"
                    _placeholder={{ color: "#75582B" }}
                    borderColor="#75582B"
                    color="#75582B"
                  />
                  <FormErrorMessage>
                    {errors.username && errors.username.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>

              {/* <FormControl isInvalid={errors.email}>
              <Box mb="24px">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  {...register("email")}
                  id="email"
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="email"
                  placeholder="example@email.com"
                  fontWeight="500"
                  size="lg"
                  _placeholder={{ color: "#75582B" }}
                  borderColor="#75582B"
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </Box>
            </FormControl> */}

              <FormControl isInvalid={errors.password}>
                <Box mb="24px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Password<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      {...register("password")}
                      fontSize="sm"
                      placeholder="Min. 8 characters"
                      size="lg"
                      type={show ? "text" : "password"}
                      variant="auth"
                      _placeholder={{ color: "#75582B" }}
                      borderColor="#75582B"
                      color="#75582B"
                    />
                    <InputRightElement
                      display="flex"
                      alignItems="center"
                      mt="4px"
                    >
                      <Icon
                        color={inputColor}
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.password && errors.password.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>

              <FormControl isInvalid={errors.confirmPassword}>
                <Box mb="24px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Confirm Password<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup>
                    <Input
                      {...register("confirmPassword")}
                      fontSize="sm"
                      placeholder="Min. 8 characters"
                      size="lg"
                      type={show ? "text" : "password"}
                      variant="auth"
                      _placeholder={{ color: "#75582B" }}
                      borderColor="#75582B"
                      color="#75582B"
                    />
                    <InputRightElement
                      display="flex"
                      alignItems="center"
                      mt="4px"
                    >
                      <Icon
                        color={inputColor}
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.confirmPassword && errors.confirmPassword.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>

              <Button
                type="submit"
                // onClick={handleSubmit}
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                bg={bgButtonGradient}
                _hover={{ opacity: 0.8 }}
              >
                <Text
                  fontFamily={"'DM Sans', sans-serif"}
                  fontWeight="700"
                  fontSize="32px"
                  lineHeight="28px"
                  textAlign="center"
                >
                  Sign Up
                </Text>
              </Button>
            </form>
          </fieldset>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Already have an account?
              <NavLink to="/auth/sign-in">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  login
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;
