// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DATA_FETCHING_STATUSES } from "lib/constants";
import axiosPrivate from "lib/config/axios";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import { ENDPOINTS } from "lib/constants";
import { format } from "date-fns";
import { REWARD_TYPES_MAP } from "lib/constants";
import { schema } from "./../../views/admin/rewards/schema";
import * as yup from "yup";

const rewardsArraySchema = yup.array().of(schema);

const REWARD_PAYLOAD_KEYS_MAP = {
  "Gift Card(Code)": "cardCode",
  "Quantity/Amount": "rewardValue",
  "Reward Date": "rewardDate",
  "Reward Type": "rewardType",
  Rank: "rank",
};

export const transformRewards = (rewards) =>
  rewards.map((reward) => {
    const updatedReward = {};
    for (const key in reward) {
      const newKey = REWARD_PAYLOAD_KEYS_MAP[key] || key; // Use mapped key or default to original
      updatedReward[newKey] = reward[key];
    }

    if (updatedReward.rewardType !== REWARD_TYPES_MAP.GIFT_CARD) {
      const { cardCode, ...rest } = updatedReward;
      return rest;
    }
    return updatedReward;
  });

export const validateRewards = async (rewardsArray) => {
  try {
    const data = await rewardsArraySchema.validate(rewardsArray, {
      abortEarly: false,
    });
    return { hasErrors: false, error: null, data };
  } catch (error) {
    console.error(error)
    return { hasErrors: true, error: error.message, data: null };
  }
};

export const fetchRewardsThunk = createAsyncThunk(
  "appRewards/fetchRewards",
  async ({ page, selectedDate, sortBy }, { dispatch }) => {
    try {
      const response = await axiosPrivate.get(`${ENDPOINTS.REWARDS}/by-date`, {
        params: {
          page,
          rewardDate: format(selectedDate, "yyyy-MM-dd"), //"2024-07-31",
          // ...(sortBy ? { sortBy } : null),
          sortBy: "rank",
        },
      });
      const rewards = response.data;
      dispatch(
        setRewards({
          data: rewards,
          status: DATA_FETCHING_STATUSES.DATA_LOADED,
        })
      );
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message?.toLowerCase() === "not found") {
        toast.error("Reward not found.");
        return;
      }
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        return;
      }
      toast.error(error?.response?.data?.message ?? "Something went wrong");
    }
  }
);

export const bulkCreateRewardsThunk = createAsyncThunk(
  "appRewards/bulkCreateRewards",
  async (rewards, { dispatch }) => {
    const transformedRewards = transformRewards(rewards);
    const { hasErrors, error, data } = await validateRewards(transformedRewards);
    if (hasErrors) {
      toast.error(error ?? "CSV file has invalid data.");
      return;
    }

    try {
      await axiosPrivate.post(`${ENDPOINTS.REWARDS}/bulk-rewards`, {
        rewards: data,
      });
      await dispatch(
        fetchRewardsThunk({
          page: 1,
          selectedDate: format(new Date(), "yyyy-MM-dd"),
        })
      );
      toast.success("Rewards successfully created");
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message?.toLowerCase() === "not found") {
        toast.error("Reward not found.");
      } else {
        toast.error(error?.response?.data?.message ?? "Something went wrong");
      }
    }
  }
);

export const createRewardsThunk = createAsyncThunk(
  "appRewards/fetchRewards",
  async (payload, { dispatch }) => {
    try {
      let formData = new FormData();
      payload.cardCode && formData.append("cardCode", payload.cardCode);
      formData.append("rank", payload.rank);
      formData.append("rewardType", payload.rewardType);
      formData.append("rewardDate", payload.rewardDate);
      formData.append("rewardValue", payload.rewardValue);

      // if (payload.cardImage) {
      //   formData.append("cardImage", payload.cardImage, payload.cardImage.name);
      // }

      await axiosPrivate.post(ENDPOINTS.REWARDS, formData, {
        headers: {
          "Content-Type": "multipart/form-data; boundary=myboundary",
        },
      });
      await dispatch(
        fetchRewardsThunk({
          page: 1,
          selectedDate: format(payload.rewardDate, "yyyy-MM-dd"),
        })
      );
      toast.success("Reward successfully created");
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message?.toLowerCase() === "not found") {
        toast.error("Reward not found.");
      } else {
        toast.error(error?.response?.data?.message ?? "Something went wrong");
      }
    }
  }
);

export const updateRewardsThunk = createAsyncThunk(
  "appRewards/updateRewards",
  async (payload, { dispatch }) => {
    try {
      const { id, selectedRewardDate, ...restPayload } = payload;
      await axiosPrivate.patch(`${ENDPOINTS.REWARDS}/${id}`, restPayload);
      await dispatch(
        fetchRewardsThunk({
          page: 1,
          selectedDate: format(selectedRewardDate, "yyyy-MM-dd"),
        })
      );
      toast.success("Reward successfully updated.");
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message?.toLowerCase() === "not found") {
        toast.error("Reward not found.");
        return;
      }
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        return;
      }
      toast.error(error?.response?.data?.message ?? "Something went wrong");
    }
  }
);

export const deleteRewardsThunk = createAsyncThunk(
  "appRewards/deleteRewards",
  async ({ id, selectedDate }, { dispatch }) => {
    try {
      await axiosPrivate.delete(`${ENDPOINTS.REWARDS}/${id}`);
      await dispatch(
        fetchRewardsThunk({
          page: 1,
          selectedDate: format(selectedDate, "yyyy-MM-dd"),
        })
      );
      toast.success("Reward successfully deleted.");
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message?.toLowerCase() === "not found") {
        toast.error("Reward not found.");
        return;
      }
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        return;
      }
      toast.error(error?.response?.data?.message ?? "Something went wrong");
    }
  }
);

const initialState = {
  rewards: {
    status: DATA_FETCHING_STATUSES.DATA_LOADING,
    data: [],
  },
};

export const rewardsSlice = createSlice({
  name: "rewardsSlice",
  initialState,
  reducers: {
    setRewards: (state, action) => {
      state.rewards = {
        ...state.rewards,
        ...action.payload,
      };
    },
  },
});
export const { setRewards } = rewardsSlice.actions;

export default rewardsSlice.reducer;

//Selector
export const useRewardsSelector = () =>
  useSelector((state) => {
    return state.rewardsReducer;
  });
