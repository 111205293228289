// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DATA_FETCHING_STATUSES } from "lib/constants";
import axiosPrivate from "lib/config/axios";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import { ENDPOINTS } from "lib/constants";
import { formatPayloadDate } from "lib/helpers";
import { getCurrentUTCDate } from "lib/helpers";

export const fetchLeaderboardThunk = createAsyncThunk(
  "appLeaderboard/fetchLeaderboard",
  async ({ page }, { dispatch }) => {
    try {
      const response = await axiosPrivate.get(ENDPOINTS.LEADERBOARD, {
        params: {
          page,

          gameDate: getCurrentUTCDate(), // "2024-07-31",
          // gameDate: formatPayloadDate(new Date().toISOString()), // "2024-07-31",
          sortBy: "score:desc,gameTime:asc",
        },
      });
      const leaderboard = response.data;
      dispatch(
        setLeaderboard({
          data: leaderboard,
          status: DATA_FETCHING_STATUSES.DATA_LOADED,
        })
      );
      return API_SUCCESS_RESPONSE_TAG;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
        return;
      }
      toast.error("Something went wrong");
    }
  }
);

const initialState = {
  leaderboard: {
    status: DATA_FETCHING_STATUSES.DATA_LOADING,
    data: [],
  },
};

export const leaderboardSlice = createSlice({
  name: "leaderboardSlice",
  initialState,
  reducers: {
    setLeaderboard: (state, action) => {
      state.leaderboard = {
        ...state.leaderboard,
        ...action.payload,
      };
    },
  },
});
export const { setLeaderboard } = leaderboardSlice.actions;

export default leaderboardSlice.reducer;

//Selector
export const useLeaderboardSelector = () =>
  useSelector((state) => {
    return state.leaderboardReducer;
  });
