import React from "react";
import {
  FormErrorMessage,
  FormControl,
  FormLabel,
  Box,
  Flex,
  Heading,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import { useAuth } from "contexts/AuthContext";
import { useAuthSelector } from "store/slices/auth";
import { forgotPasswordThunk } from "store/slices/auth";
import { useDispatch } from "react-redux";
import { API_SUCCESS_RESPONSE_TAG } from "lib/constants";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Card from "components/card/Card";
import { PrimayButton } from "components/customs/buttons";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { CustomFormInput } from "components/customs/Input";

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email.")
    .required("Email is required."),
});

export function ForgotPassword() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "#D0BEA3";
  const inputColor = "#75582B";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const bgButtonGradient = "linear-gradient(180deg, #FFCE88 0%, #DD9122 100%)";
  const { login } = useAuth();
  const [show, setShow] = React.useState(false);

  const dispatch = useDispatch();
  const handleClick = () => setShow(!show);
  const auth = useAuthSelector();
  const history = useHistory();
  const methods = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const res = await dispatch(forgotPasswordThunk({ email: data?.email }));
    if (res.payload === API_SUCCESS_RESPONSE_TAG) {
      // history.push("/auth/reset-password");
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box
          me="auto"
          // sx={{
          //   display: "flex",
          //   flexDirection: "column",
          //   justifyContent: "center",
          //   alignItems: "center",
          // }}
        >
          <Heading
            textAlign="center"
            color={textColor}
            fontSize="36px"
            mb="10px"
          >
            Forgot Password
          </Heading>
          <Text
            // textAlign="center"
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email.
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Flex align="center" mb="25px">
            <HSeparator />
          </Flex>
          <FormProvider {...methods}>
            <fieldset disabled={isSubmitting}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CustomFormInput
                  {...{
                    name: "email",
                    placeholder: "Enter your email",
                    type: "text",
                    label: "Email",
                    isRequired: true,
                  }}
                />
                {/* <Box mb="24px">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Email<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    {...register("email")}
                    id="email"
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="email"
                    placeholder="example@email.com"
                    fontWeight="500"
                    size="lg"
                    _placeholder={{ color: "#75582B" }}
                    borderColor="#75582B"
                    color="#75582B"
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </Box> */}

                <PrimayButton
                  width="100%"
                  label="Submit"
                  isLoading={isSubmitting}
                />

                {/* <Flex justifyContent="center"> */}
                {/* </Flex> */}
                {/* <Button
                isLoading={isSubmitting}
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                bg={bgButtonGradient}
                _hover={{ opacity: 0.8 }}
              >
                <Text
                  fontFamily={"'DM Sans', sans-serif"}
                  fontWeight="700"
                  fontSize="32px"
                  lineHeight="28px"
                  textAlign="center"
                >
                  Invite
                </Text>
              </Button> */}
              </form>
            </fieldset>
          </FormProvider>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}
