import { format } from "date-fns";
import {
  LOCAL_STROAGE_USER_GET_KEY,
  REWARD_TYPES_MAP,
} from "./constants";
import { rewardsArraySchema } from "views/admin/rewards/schema";

export function formatDate(date) {
  if (!date) return "";
  return format(new Date(date), "MM/dd/yyyy hh:mm aa");
}

export function formatRewardType(value) {
  if (!value) return "";
  return value
    .split("_")
    .map((word) => word[0] + word.slice(1).toLowerCase())
    .join(" ");
}

export function formatPayloadDate(value) {
  if (!value) return "";
  return format(new Date(value), "yyyy-MM-dd");
}

export function isGiftCard(value) {
  if (!value) return false;
  return value === REWARD_TYPES_MAP.GIFT_CARD;
}

export function isGoldenAcorns(value) {
  if (!value) return false;
  return value.toUpperCase() === REWARD_TYPES_MAP.GOLDEN_ACORNS;
}

export function isSimpleAcorns(value) {
  if (!value) return false;
  if (isGiftCard(value) || isGoldenAcorns(value)) return false;
  return true;
}

export function checkRewardType(value) {
  if (!value) return false;
  const is_gift_card = isGiftCard(value);
  const is_golden_acorns = isGoldenAcorns(value);
  const is_simple_acorns = isSimpleAcorns(value);
  return {
    is_gift_card,
    is_golden_acorns,
    is_simple_acorns,
  };
}

export function setTokensInLocalStorage({ accessToken, refreshToken }) {
  // Retrieve the current "persist:USER" data from localStorage
  const persistedData = localStorage.getItem(LOCAL_STROAGE_USER_GET_KEY);

  // Parse the JSON string into an object
  if (persistedData) {
    const parsedData = JSON.parse(persistedData);

    // Parse the nested user data string (if it exists) to access tokens
    const userData = JSON.parse(parsedData.user);

    // Update tokens inside the nested user data
    if (userData && userData.data && userData.data.tokens) {
      userData.data.tokens.access.token = accessToken;
      userData.data.tokens.refresh.token = refreshToken;

      // Stringify the modified user data
      parsedData.user = JSON.stringify(userData);
      // Save the updated data back into localStorage
      localStorage.setItem("persist:USER", JSON.stringify(parsedData));
    } else {
      console.error("Tokens object not found in persisted data.");
    }
  } else {
    console.error("No persisted user data found in localStorage.");
  }
}

export function getAuthTokens() {
  const userFromLocalStroage = localStorage.getItem(LOCAL_STROAGE_USER_GET_KEY);
  if (!userFromLocalStroage) return null;
  const rawUser = JSON.parse(userFromLocalStroage)?.user;
  const user = JSON.parse(rawUser);
  const accessToken = user?.data?.tokens?.access?.token || null;
  const refreshToken = user?.data?.tokens?.refresh?.token || null;
  return {
    accessToken: accessToken ?? null,
    refreshToken: refreshToken ?? null,
  };
}

export const REWARD_OPTIONS = Object.entries(REWARD_TYPES_MAP).map(
  (reward) => ({
    label: formatRewardType(reward[0]),
    value: reward[1],
  })
);

export const REWARDS_VALUES = () => Array.from({ length: 20 }, (_, i) => i + 1);
export const RANK_TYPES_OPTIONS = REWARDS_VALUES().map((i) => ({
  label: i,
  value: i,
}));

export const isAdmin = (role) => {
  if (!role) return false;
  const roles = ["admin", "admins", "superAdmin"];
  return roles.includes(role);
};

export const getCurrentUTCDate = () => {
  const now = new Date();
  const utcYear = now.getUTCFullYear();
  const utcMonth = String(now.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const utcDay = String(now.getUTCDate()).padStart(2, "0");
  // const utcHours = String(now.getUTCHours()).padStart(2, "0");
  // const utcMinutes = String(now.getUTCMinutes()).padStart(2, "0");
  // const utcSeconds = String(now.getUTCSeconds()).padStart(2, "0");
  return `${utcYear}-${utcMonth}-${utcDay}`;
};

export function formatGameTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  // Pad each component to ensure it has two digits
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(secs).padStart(2, "0");

  return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
}

export const isObject = (value) => {
  return (
    (value !== null || value !== undefined) &&
    !Array.isArray(value) &&
    typeof value === "object"
  );
};

export const nullableObject = (object) => {
  if (!isObject(object)) return null;
  const [[name, value]] = Object.entries(object);
  if (!value) return null;
  return {
    [name]: value,
  };
};
