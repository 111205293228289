import { useFormContext } from "react-hook-form";
import {
  Box,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Text,
  useColorModeValue,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Textarea,
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useState } from "react";

export function CustomFormInput({
  placeholder = "",
  type = "text",
  name = "",
  label,
  isRequired = false,
}) {
  const {
    formState: { errors },
  } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const inputColor = "#75582B";

  return (
    <FormControl isInvalid={errors[name]}>
      <CustomFormLabel {...{ label, isRequired }} />
      <Box mb="24px">
        <InputGroup size="md">
          <CustomInput
            {...{
              name,
              type: showPassword ? "text" : type,
              placeholder,
            }}
          />
          {type === "password" && (
            <InputRightElement display="flex" alignItems="center" mt="4px">
              <Icon
                color={inputColor}
                _hover={{ cursor: "pointer" }}
                as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                onClick={() => setShowPassword((prev) => !prev)}
              />
            </InputRightElement>
          )}
        </InputGroup>
        <FormErrorMessage>
          {errors?.[name] && errors?.[name]?.message}
        </FormErrorMessage>
      </Box>
    </FormControl>
  );
}

export function CustomInput({
  placeholder = "",
  type = "text",
  name = "",
  styles = {},
  error // Accept error as a prop
}) {
  const { register } = useFormContext();

  return (
    <Box width="100%">
      {type === "textarea" ? (
        <Textarea
          {...register(name)}
          id={name}
          placeholder={placeholder}
          fontSize="18px"
          fontWeight="500"
          resize="none"
          overflowY="auto"
          whiteSpace="pre-wrap"
          wordBreak="break-word"
          _placeholder={{
            color: '#fff',
            textAlign: "left",
            fontSize: "18px",
          }}
          _focus={{
            zIndex: 1,
            borderColor: "#996c3f",
            boxShadow: "0 0 0 1px #996c3f",
          }}
          {...styles}
        />
      ) : (
        <Input
          {...register(name)}
          id={name}
          variant="auth"
          fontSize="sm"
          ms={{ base: "0px", md: "0px" }}
          type={type}
          placeholder={placeholder}
          fontWeight="500"
          size="lg"
          _placeholder={{ color: "grey.500" }}
          borderColor="brand.950"
          color="grey.700"
          {...styles}
        />
      )}

      {error && <Text color="red.500" fontSize="sm" mt={1} ml={4} textAlign='left'>{error}</Text>}
    </Box>
  );
}

export function CustomFormLabel({ label, isRequired = false }) {
  const textColor = useColorModeValue("brand.950", "white");
  const brandStars = useColorModeValue("red.400", "brand.400");
  return (
    <FormLabel
      display="flex"
      ms="4px"
      fontSize="sm"
      fontWeight="500"
      color={textColor}
      mb="8px"
    >
      {label}
      {isRequired && <Text color={brandStars}>*</Text>}
    </FormLabel>
  );
}

export function SearchBar({ styles, search, handleSearch }) {
  return (
    <Input
      value={search}
      onChange={(e) => {
        handleSearch(e.target.value);
      }}
      variant="auth"
      fontSize="sm"
      ms={{ base: "0px", md: "0px" }}
      type="text"
      placeholder="Search..."
      fontWeight="500"
      size="lg"
      _placeholder={{ color: "grey.500" }}
      borderColor="brand.950"
      color="grey.700"
      {...styles}
    />
  );
}
