import React, {
  useMemo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import socketio from "socket.io-client";
import { SOCKET_URL } from "lib/constants";
import { getAuthTokens } from "lib/helpers";
import { useAuth } from "./AuthContext";
import { SOCKET_EVENTS } from "lib/constants";
import { useDispatch } from "react-redux";
import { setHasNewNotifications } from "store/slices/notifications";
import { setUpdateNotifications } from "store/slices/notifications";
import { useHistory } from "react-router-dom";
import { useAuthSelector } from "store/slices/auth";
import { loadNotificationsCountThunk } from "store/slices/notifications";
import { setNotificationsCount } from "store/slices/notifications";
import { useNotificationsCountSelector } from "store/slices/notifications";
import { useNotificationTypeSelector } from "store/slices/notifications";

const SocketContext = React.createContext();
let socketInstance = null;
let currentToken = null;
const getSocket = ({ isAuthenticated }) => {
  const accessToken = getAuthTokens()?.accessToken;
  if (!isAuthenticated) return null;
  if (accessToken && accessToken !== currentToken) {
    const accessToken = getAuthTokens()?.accessToken;
    currentToken = accessToken; // Update the current token
    if (socketInstance) {
      socketInstance.disconnect(); // Disconnect the previous socket instance if it exists
    }

    socketInstance = socketio.connect("wss://api-sqrrrrl.invo.zone", {
      reconnection: true,
      reconnectionAttempts: 10,
      reconnectionDelay: 1000,
      auth: { token: accessToken },
      query: { token: accessToken },
    });
  }

  return socketInstance;
};

export function SocketProvider({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const notificationType = useNotificationTypeSelector();
  const [isConnected, setIsConnected] = useState(false);

  const auth = useAuth();
  const authState = useAuthSelector();
  const user = authState?.user?.data?.user;

  const notificationsCount = useNotificationsCountSelector();

  const socket = useMemo(
    () =>
      getSocket({
        isAuthenticated: auth?.isAuthenticated,
        accessToken: auth?.accessToken,
      }),
    [auth]
  );

  useEffect(() => {
    const isAllowedPath = !["/auth/welcome", "/auth/welcome/"].includes(
      history.location.pathname
    );
    
    if (user && isAllowedPath && auth?.refreshToken) {
      dispatch(loadNotificationsCountThunk({}));
    }
  }, [history.location.pathname, auth?.refreshToken]);

  useEffect(() => {
    if (!socket) return;

    // Set connection status
    socket.on("connect", () => {
      setIsConnected(true);
      console.log("Connected to socket server:", socket.id);
    });

    // Handle disconnection
    socket.on("disconnect", () => {
      setIsConnected(false);
      console.log("Disconnected to socket server:", socket.id);
    });

    socket.on("error", function (err) {
      socket.socket.reconnect();
    });

    // Clean up the connection when component unmounts
    return () => {
      if (socket && isConnected) {
        socket.disconnect();
        console.log("Socket connection closed");
      }
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) return;
    const handleReceiveNotification = (notification) => {
      if (notificationType !== "read") {
        dispatch(setUpdateNotifications(notification));
      }
      const newCount = notificationsCount + 1;
      dispatch(setNotificationsCount({ count: newCount }));
      if (
        !["/admin/notifications", "/admin/notifications/"].includes(
          history.location.pathname
        )
      ) {
        dispatch(setHasNewNotifications(true));
      }
    };

    socket.on(SOCKET_EVENTS.RECEIVE_NOTIFICATIONS, handleReceiveNotification);
    return () => {
      socket.off(
        SOCKET_EVENTS.RECEIVE_NOTIFICATIONS,
        handleReceiveNotification
      );
    };
  }, [socket, notificationsCount]);

  return (
    <SocketContext.Provider value={{ socket, isConnected }}>
      {children}
    </SocketContext.Provider>
  );
}

export function useSocketContext() {
  const socket = useContext(SocketContext);
  return socket;
}
