// Chakra imports
import { Box, Flex, SimpleGrid, Spinner } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useAuthSelector } from "store/slices/auth";
import { useDispatch } from "react-redux";
import { fetchAchievementsThunk } from "store/slices/auth";
import { achievementsColumns } from "./achievementsColumns";
import { DATA_FETCHING_STATUSES } from "lib/constants";
import { AchievementsTable } from "./achievementsTable";
import { useColors } from "components/customs/colors";
import { useHistory, useLocation } from "react-router-dom";
import { useLoadingSelector } from "store/slices/loading";
import { formatDate } from "lib/helpers";
import { CustomClaimTag } from "components/customs/tags";
import { formatRewardType } from "lib/helpers";
import { useSearchParams } from "../rewards/reward";

export function Achievements() {
  const { textColor } = useColors();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useAuthSelector();
  const { searchParams } = useSearchParams();
  const userId = searchParams.get("id");
  const achievements = auth?.achievements;
  const totalPages = achievements?.data?.totalPages;
  const loading = useLoadingSelector();
  const [currentPage, setCurrentPage] = useState(1); // Start from 0

  useEffect(() => {
    dispatch(fetchAchievementsThunk({ page: currentPage, userId }));
  }, [currentPage, userId]);

  const tableRows = useMemo(
    () =>
      achievements?.data?.results?.map((achievement) => ({
        id: achievement.id,
        title: achievement?.rewardType
          ? formatRewardType(achievement?.rewardType)
          : "",
        reward_value: achievement.rewardValue ?? "N/A",
        claim_status: achievement.claim_status ? (
          <CustomClaimTag label="claimed" />
        ) : (
          <CustomClaimTag label="pending" />
        ),
        reward_awarded_date: achievement?.rewardDate
          ? formatDate(new Date(achievement?.rewardDate))
          : "N/A",
      })),
    [auth, loading]
  );

  let component = (
    <Flex justifyContent="center" mt="28">
      <Spinner size="xl" color="brand.950" />
    </Flex>
  );
  if (achievements?.status === DATA_FETCHING_STATUSES.DATA_LOADED) {
    component = (
      <AchievementsTable
        columnsData={achievementsColumns}
        tableData={tableRows}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    );
  }
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mb="20px" spacing={{ base: "20px", xl: "20px" }}>
        {component}
      </SimpleGrid>
    </Box>
  );
}
