import { isAdmin } from "lib/helpers";

export const usersTableColumns = (role) => [
  {
    Header: "Username",
    accessor: "username",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  ...(isAdmin(role)
    ? []
    : [
        {
          Header: "Last Login",
          accessor: "last_login",
        },
      ]),
  ...(isAdmin(role)
    ? []
    : [
        {
          Header: "Last Play",
          accessor: "last_played",
        },
      ]),
  {
    Header: "Actions",
    accessor: "actions",
  },
];
