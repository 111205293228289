import {
  isPending,
  isFulfilled,
  isRejected,
  configureStore,
} from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { LOCAL_STROAGE_KEYS } from "lib/constants";
import storage from "redux-persist/lib/storage";

// Reducers
import paginationReducer from "./slices/pagination";
import loadingReducer, { setLoading } from "./slices/loading";
import authSliceReducer from "./slices/auth";
import fileUploaderReducer from "./slices/fileUploader";
import leaderboardReducer from "./slices/leaderboard";
import rewardsReducer from "./slices/rewards";
import chatsReducer from "./slices/chats";
import notificationsReducer from "./slices/notifications";
import chatUsersReducer from "./slices/chatUsers";

export const loadingMiddleware = (storeAPI) => (next) => (action) => {
  if (isPending(action)) {
    storeAPI.dispatch(setLoading(true));
  } else if (isFulfilled(action) || isRejected(action)) {
    storeAPI.dispatch(setLoading(false));
  }
  return next(action);
};

const persistConfig = {
  key: LOCAL_STROAGE_KEYS.USER,
  storage,
  whitelist: ["user"],
};

const persistedAuthReducer = persistReducer(persistConfig, authSliceReducer);

export const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    authReducer: persistedAuthReducer,
    loadingReducer,
    paginationReducer,
    fileUploaderReducer,
    leaderboardReducer,
    rewardsReducer,
    chatsReducer,
    notificationsReducer,
    chatUsersReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }).concat(loadingMiddleware),
});

export const persistor = persistStore(store);
